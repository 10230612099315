var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formBisnis"},[_c('b-form',{attrs:{"id":("form-edit-probis-" + (_vm.appData.proses_bisnis_id))}},[_c('b-card',{staticClass:"border-bottom"},[_c('b-row',[(_vm.saktiFailed)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Konektivitas dengan "),_c('u',[_vm._v("Sakti")]),_vm._v(" gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi "),_c('u',[_vm._v("Admin")])])])])],1):_vm._e(),_c('alert-module-detail',{attrs:{"doc-data":_vm.appData}}),(_vm.programUpdate)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Perhatian ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Nama Program disesuaikan dengan data "),_c('u',[_vm._v("Sakti")]),_vm._v(", periksa kembali isian Domain Proses Bisnis Anda.")])])])],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Program sesuai dengan Renja'),expression:"'Nama Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Program","label-for":"vi-nama_program"}},[_c('validation-provider',{attrs:{"name":"Nama Program","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_program","required":"","name":"nama_program","placeholder":"Nama Program","disabled":(_vm.saktiProgram !== '') ? true : false},model:{value:(_vm.appData.nama_program),callback:function ($$v) {_vm.$set(_vm.appData, "nama_program", $$v)},expression:"appData.nama_program"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sektor Pemerintahan","label-for":"vi-gov_sector_id"}},[_c('validation-provider',{attrs:{"name":"Sektor Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-gov_sector_id","required":"","name":"gov_sector_id","options":_vm.referenceData.ref_sektor_pemerintahan,"placeholder":"Sektor Pemerintahan","label":"nama"},on:{"input":_vm.getDaftarUrusan},model:{value:(_vm.gov_sector_id),callback:function ($$v) {_vm.gov_sector_id=$$v},expression:"gov_sector_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Urusan Pemerintahan","label-for":"vi-gov_affair_id"}},[_c('validation-provider',{attrs:{"name":"Urusan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"gov_affair_id",attrs:{"id":"vi-gov_affair_id","required":"","name":"gov_affair_id","options":_vm.daftarUrusan,"placeholder":"Urusan Pemerintahan","label":"nama"},on:{"input":_vm.getFungsiGov},model:{value:(_vm.gov_affair_id),callback:function ($$v) {_vm.gov_affair_id=$$v},expression:"gov_affair_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Fungsi Pemerintahan","label-for":"vi-gov_function"}},[_c('validation-provider',{attrs:{"name":"Fungsi Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.fungsi_and_sub)?_c('v-select',{ref:"gov_function",attrs:{"id":"vi-gov_function","required":"","name":"gov_function","options":_vm.daftarFungsi,"placeholder":"Fungsi Pemerintahan","label":"nama"},on:{"input":_vm.getSubFungsiGov},model:{value:(_vm.gov_function),callback:function ($$v) {_vm.gov_function=$$v},expression:"gov_function"}}):_vm._e(),(_vm.fungsi_and_sub)?_c('b-input-group',[(_vm.fungsi_and_sub)?_c('b-form-input',{attrs:{"id":"vi-gov_function","required":"","name":"gov_function","placeholder":"Fungsi Pemerintahan"},model:{value:(_vm.fungsi_pemerintahan_nama),callback:function ($$v) {_vm.fungsi_pemerintahan_nama=$$v},expression:"fungsi_pemerintahan_nama"}}):_vm._e(),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('cat')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sub Fungsi Pemerintahan","label-for":"vi-gov_sub_function"}},[_c('validation-provider',{attrs:{"name":"Sub Fungsi Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.sub_fungsi)?_c('v-select',{ref:"gov_sub_function",attrs:{"id":"vi-gov_sub_function","required":"","name":"gov_sub_function","options":_vm.daftarSubFungsi,"placeholder":"Sub Fungsi Pemerintahan","label":"nama"},on:{"input":_vm.setSubFungsiGov},model:{value:(_vm.gov_sub_function),callback:function ($$v) {_vm.gov_sub_function=$$v},expression:"gov_sub_function"}}):_vm._e(),(_vm.sub_fungsi)?_c('b-input-group',[(_vm.sub_fungsi)?_c('b-form-input',{attrs:{"id":"vi-gov_sub_function","required":"","name":"gov_sub_function","placeholder":"Sub Fungsi Pemerintahan"},model:{value:(_vm.sub_fungsi_pemerintahan_nama),callback:function ($$v) {_vm.sub_fungsi_pemerintahan_nama=$$v},expression:"sub_fungsi_pemerintahan_nama"}}):_vm._e(),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('sub')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'),expression:"'Merupakan urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Bisnis / Urusan","label-for":"vi-nama_urusan"}},[_c('validation-provider',{attrs:{"name":"Nama Bisnis / Urusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_urusan","required":"","name":"nama_urusan","placeholder":"Nama Bisnis / Urusan"},model:{value:(_vm.appData.nama_urusan),callback:function ($$v) {_vm.$set(_vm.appData, "nama_urusan", $$v)},expression:"appData.nama_urusan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Instansi yang mengajukan'),expression:"'Instansi yang mengajukan'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Instansi","label-for":"vi-instansi_id"}},[_c('b-form-input',{attrs:{"hidden":"","required":"","name":"instansi_id","placeholder":"Instansi"},model:{value:(_vm.appData.instansi_id),callback:function ($$v) {_vm.$set(_vm.appData, "instansi_id", $$v)},expression:"appData.instansi_id"}}),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Instansi"},model:{value:(_vm.appData.instansi),callback:function ($$v) {_vm.$set(_vm.appData, "instansi", $$v)},expression:"appData.instansi"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan uraian dari urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'),expression:"'Merupakan uraian dari urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Uraian Bisnis / Urusan","label-for":"vi-deskripsi_urusan"}},[_c('validation-provider',{attrs:{"name":"Uraian Urusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi_urusan","rows":"4","required":"","name":"deskripsi_urusan","placeholder":"Uraian Bisnis / Urusan"},model:{value:(_vm.appData.deskripsi_urusan),callback:function ($$v) {_vm.$set(_vm.appData, "deskripsi_urusan", $$v)},expression:"appData.deskripsi_urusan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-success"},on:{"click":_vm.simpanBisnis}},[_c('span',[_vm._v("Simpan Referensi Proses Bisnis")])]),(_vm.dataSaved)?_c('b-button',{staticClass:"mt-0 mt-md-2 ml-50",attrs:{"variant":"flat-success","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"14"}}),_vm._v(" Referensi Proses Bisnis Berhasil Disimpan ")],1):_vm._e()],1)],1)],1)],1),(_vm.errorMsg)?_c('div',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found ")]),_c('div',{staticClass:"alert-body"},[(typeof _vm.errorMsg === 'object')?_c('ul',_vm._l((_vm.errorMsg),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "),_vm._l((item),function(child,indexs){return _c('span',{key:indexs},[_vm._v(" "+_vm._s(child)+" ")])})],2)}),0):_c('span',[_vm._v(_vm._s(_vm.errorMsg))])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }