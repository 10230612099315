<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Data &amp; Informasi
        </h5>
        <small class="text-muted">Arsitektur Domain Data dan Informasi SPBE.</small>
      </b-col>
      <alert-module-detail :doc-data="dataData" />
      <b-col
        v-if="!dataData.nama"
        md="12"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Data dan Informasi sesuai dengan Renja'"
          label-for="vi-master_data"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Data dan Informasi atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_data"
              v-model="master_data"
              required
              name="master_data"
              :options="referenceData.data_informasi_list"
              placeholder="Pilih Nama Data dan Informasi atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col :md="coreData.tahun_anggaran===2025? 6 : 12">
        <b-row>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
              label="Data Pokok"
              label-for="vi-data_basic_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Pokok"
                rules="required"
              >
                <v-select
                  id="vi-data_basic_id"
                  v-model="data_basic_id"
                  required
                  name="data_basic_id"
                  :options="referenceData.ref_data_pokok"
                  placeholder="Data Pokok"
                  label="nama"
                  @input="getDataTematik()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
              label="Data Tematik"
              label-for="vi-data_tematic_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Tematik"
                rules="required"
              >
                <v-select
                  id="vi-data_tematic_id"
                  v-model="data_tematic_id"
                  required
                  name="data_tematic_id"
                  :options="daftarDataTematik"
                  placeholder="Data Tematik"
                  label="nama"
                  @input="getDataTopik"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
              label="Data Topik"
              label-for="vi-data_topic_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Topik"
                rules="required"
              >
                <v-select
                  v-if="!fungsi_and_sub"
                  id="vi-data_topic_id"
                  v-model="data_topic_id"
                  required
                  name="data_topic_id"
                  :options="daftarDataTopik"
                  placeholder="Data Topik"
                  label="nama"
                  @input="getDataSubTopik"
                />
                <b-input-group
                  v-if="fungsi_and_sub"
                >
                  <b-form-input
                    v-if="fungsi_and_sub"
                    id="vi-data_topic_id"
                    v-model="data_topic_nama"
                    required
                    name="data_topic_id"
                    placeholder="Data Topik"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-secondary"
                      @click="unitReset('cat')"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        size="12"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
              label="Data Sub Topik"
              label-for="vi-sub_data_topic_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Sub Topik"
                rules="required"
              >
                <v-select
                  v-if="!sub_fungsi"
                  id="vi-sub_data_topic_id"
                  v-model="sub_data_topic_id"
                  required
                  name="sub_data_topic_id"
                  :options="daftarDataSubTopik"
                  placeholder="Data Sub Topik"
                  label="nama"
                  @input="setSubFungsiGov"
                />
                <b-input-group
                  v-if="sub_fungsi"
                >
                  <b-form-input
                    v-if="sub_fungsi"
                    id="vi-sub_data_topic_id"
                    v-model="sub_data_topic_nama"
                    required
                    name="sub_data_topic_id"
                    placeholder="Data Sub Topik"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-secondary"
                      @click="unitReset('sub')"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        size="12"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'"
              label="Nama Data"
              label-for="vi-nama"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Data"
                rules="required"
              >
                <b-form-input
                  id="vi-nama"
                  v-model="dataData.nama"
                  required
                  name="nama"
                  placeholder="Nama Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 6: 3">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
              label="Interoperabilitas Data"
              label-for="vi-data_interoperability_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Interoperabilitas Data"
                rules="required"
              >
                <v-select
                  id="vi-data_interoperability_id"
                  v-model="data_interoperability_id"
                  required
                  name="data_interoperability_id"
                  :options="daftarDataInteroperability"
                  placeholder="Interoperabilitas Data"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 6: 3">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
              label="Sifat Data"
              label-for="vi-data_status_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Sifat Data"
                rules="required"
              >
                <v-select
                  id="vi-data_status_id"
                  v-model="data_status_id"
                  required
                  name="data_status_id"
                  :options="referenceData.ref_sifat_data"
                  placeholder="Sifat Data"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari pemanfaatan data.'"
              label="Tujuan Data"
              label-for="vi-tujuan"
            >
              <validation-provider
                #default="{ errors }"
                name="Tujuan Data"
                rules="required"
              >
                <b-form-textarea
                  id="vi-tujuan"
                  v-model="dataData.tujuan"
                  rows="4"
                  required
                  name="tujuan"
                  placeholder="Tujuan Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 6: 3">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan tanggal mulai berlakunya data.'"
              label="Awal Validitas Data"
              label-for="vi-valid_start"
            >
              <validation-provider
                #default="{ errors }"
                name="Awal Validitas Data"
                rules="required"
              >
                <b-form-datepicker
                  id="vi-valid_start"
                  v-model="dataData.valid_start"
                  :max="dataData.valid_end"
                  :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                  size="sm"
                  required
                  name="valid_start"
                  placeholder="Awal Validitas Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 6: 3">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan tanggal akhir berlakunya data.'"
              label="Akhir Validitas Data"
              label-for="vi-valid_end"
            >
              <validation-provider
                #default="{ errors }"
                name="Akhir Validitas Data"
                rules="required"
              >
                <b-form-datepicker
                  id="vi-valid_end"
                  v-model="dataData.valid_end"
                  :min="dataData.valid_start"
                  :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                  size="sm"
                  required
                  name="valid_end"
                  placeholder="Akhir Validitas Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan penanggung jawab dari data.'"
              label="Penanggung Jawab"
              label-for="vi-pic_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Penanggung Jawab"
                rules="required"
              >
                <b-form-input
                  id="vi-pic_name"
                  v-model="dataData.pic_name"
                  required
                  name="pic_name"
                  placeholder="Penanggung Jawab"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'"
              label="Uraian Data"
              label-for="vi-deskripsi"
            >
              <validation-provider
                #default="{ errors }"
                name="Uraian Data"
                rules="required"
              >
                <b-form-textarea
                  id="vi-deskripsi"
                  v-model="dataData.deskripsi"
                  rows="4"
                  required
                  name="deskripsi"
                  placeholder="Uraian Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran==2025? 12 : 6">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan wali dari data.'"
              label="Wali Data"
              label-for="vi-wali_data"
            >
              <validation-provider
                #default="{ errors }"
                name="Wali Data"
                rules="required"
              >
                <b-form-input
                  id="vi-wali_data"
                  v-model="dataData.walidata"
                  required
                  name="wali_data"
                  placeholder="Wali Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <div style="padding:10px;min-height:400px;border:1px solid #999">
          <h5>Data SIA SPBE</h5>
          <div
            v-for="(value, index) in siaMapData"
            :key="index"
          >
            <template v-if="typeof value === 'object' && value !== null">
              <span class="font-weight-bolder">{{ index }}:</span>
              <Nested-sia-detail :obj="value" />
            </template>
            <template v-else>
              <span class="font-weight-bolder">{{ index }}:</span> {{ value }}
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
    <template v-if="coreData.jenis_pengadaan_id == '3' || coreData.jenis_pengadaan_id == '6'">
      <b-row
        class="mb-1"
      >
        <b-col>
          <p>Dokumen Rujukan</p>
          <b-list-group flush>
            <b-list-group-item
              v-for="(key, idx) in referenceData.ref_document_rujukan_data"
              :key="idx"
            >
              <b-form-checkbox
                :ref="`tipe-${idx}`"
                v-model="rujukan_select"
                :value="key.nama"
                plain
                class="pl-2"
                @change="setRujukan"
              >
                {{ key.nama }}
              </b-form-checkbox>
              <template v-if="key.nama !== 'Lainnya' && rujukan_select.includes(key.nama)">
                <div
                  v-if="key.childreen.length > 2"
                  class="ml-2 my-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`Dokumen Rujukan ${key.nama}`"
                    rules="required"
                  >
                    <v-select
                      :id="`vi-indikator_rujukan-${idx}`"
                      v-model="rujukan_select_dtl[key.nama]"
                      multiple
                      required
                      name="indikator_rujukan"
                      :options="key.childreen"
                      placeholder="Pilih Nama Data atau Indikator"
                      label="nama"
                      @input="setRujukan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </template>
              <template v-if="key.nama === 'Lainnya' && rujukan_select.includes(key.nama)">
                <b-row class="mt-1">
                  <b-col>
                    <b-form-group
                      label="Regulasi"
                      label-for="regulasi"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Dokumen Rujukan Lainnya"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-regulasi"
                          v-model="rujukan_regulasi"
                          required
                          name="regulasi"
                          placeholder="Regulasi"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="rujukan_file_uri"
                    cols="2"
                  >
                    <p>Dokumen Saat Ini<br>
                      <b-button
                        variant="success"
                        size="sm"
                        class="mr-25 mb-25"
                        @click="viewFile(rujukan_file_uri)"
                      >
                        <feather-icon
                          icon="BookOpenIcon"
                          size="12"
                        /></b-button>
                      <b-link
                        v-b-tooltip.hover.top="rujukan_file_uri.substring(rujukan_file_uri.lastIndexOf('/') + 1)"
                        :href="getDoc(rujukan_file_uri)"
                        class="btn btn-sm btn-info mr-25 mb-25"
                        target="_blank"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          size="12"
                        /></b-link>
                    </p>
                  </b-col>
                  <b-col>
                    <b-form-group
                      v-b-tooltip.hover.top="'Upload File Regulasi Lain yang mengatur penugasan'"
                      label="Upload File"
                      label-for="rujukan_file"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File Dokumen Rujukan Lainnya"
                        :rules="(!rujukan_file_uri) ? 'required' : ''"
                      >
                        <b-form-file
                          ref="rujukan_file"
                          v-model="rujukan_file"
                          name="rujukan_file"
                          :required="(!rujukan_file_uri) ? true : false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col md="6">
          <p class="mb-50 pb-0">
            Jenis Data yang Dikumpulkan/Dihasilkan
          </p>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="jenis_data[0]"
              class="custom-control-primary"
            >
              Data Geospasial
              <b-link
                v-b-tooltip.hover.top="'Unduh Contoh Form Daftar Data Spasial'"
                href="/panduan/form_daftar_data_spasial_clearance_spba_ta_2024.xlsx"
                target="_blank"
                class="ml-25"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
              </b-link>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="jenis_data[1]"
              class="custom-control-primary"
            >
              Data Statistik
              <b-link
                v-b-tooltip.hover.top="'Unduh Contoh Form Daftar Data Statistik'"
                href="/panduan/form_daftar_data_statistik_clearance_spba_ta_2024.xlsx"
                target="_blank"
                class="ml-25"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
              </b-link>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="jenis_data[2]"
              class="custom-control-primary"
            >
              Data Lainnya
              <b-link
                v-b-tooltip.hover.top="'Unduh Contoh Form Daftar Data Lainnya'"
                href="/panduan/form_daftar_data_lainnya_clearance_spba_ta_2024.xlsx"
                target="_blank"
                class="ml-25"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
              </b-link>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="jenis_data[0]"
        class="mb-2"
      >
        <b-col cols="12">
          <hr>
          <h5 class="my-1">
            Belanja Data Geospasial
          </h5>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            label="Kategori Jenis Belanja Data Spasial"
            label-for="vi-jenis_data_spasial"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Jenis Belanja Data Spasial"
              rules="required"
            >
              <v-select
                id="vi-jenis_data_spasial"
                v-model="kategoriJenisData[0]"
                required
                name="jenis_data_spasial"
                :options="daftarJenisDataSpasial"
                placeholder="Kategori Jenis Belanja Data Spasial"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Unit yang berperan sebagai produsen Data Spasial (Satuan Kerja Eselon II).'"
            label="Produsen Data Spasial (Satuan Kerja Eselon II)"
            label-for="vi-produsen_spasial_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Produsen Data Spasial (Satuan Kerja Eselon II)"
              rules="required"
            >
              <v-select
                id="vi-produsen_spasial_1"
                v-model="produsen[0]"
                required
                name="produsen_spasial_1"
                :options="referenceData.ref_eselon_dua"
                placeholder="Produsen Data Spasial"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Aplikasi yang berperan sebagai pendukung / pengumpulan Data Spasial.'"
            label="Aplikasi Pendukung/Pengumpulan Data (Spasial)"
            label-for="vi-aplikasi_spasial_1"
          >
            <b-form-input
              id="vi-aplikasi_spasial_1"
              v-model="aplikasi[0]"
              name="aplikasi_spasial_1"
              placeholder="Aplikasi Pendukung/Pengumpulan Data (Spasial)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
            label="Sifat Data (Spasial)"
            label-for="vi-data_status_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Sifat Data (Spasial)"
              rules="required"
            >
              <v-select
                id="vi-data_status_1"
                v-model="sifat[0]"
                required
                name="data_status_1"
                :options="referenceData.ref_sifat_data"
                placeholder="Sifat Data (Spasial)"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran[0] !== '') ? ': Rp. ' + Number(total_anggaran[0]).toLocaleString() : ''}`"
            label-for="vi-total_anggaran_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran_1"
                v-model="total_anggaran[0]"
                type="number"
                name="total_anggaran_1"
                required
                placeholder="Total Anggaran"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-row>
            <b-col
              v-if="fileUri2[0]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-button
                  variant="success"
                  size="sm"
                  class="p-25"
                  @click="viewFile(fileUri2[0])"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-b-tooltip.hover.top="fileUri2[0].substring(fileUri2[0].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri2[0])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                </b-link>
              </p>
            </b-col>
            <b-col>
              <b-form-group
                v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf), mohon dilengkapi dengan mekanisme penyelenggaraan IGT dan status pemenuhan SDI'"
                label="Kerangka Acuan Kerja (dalam format PDF)"
                label-for="file_kak_1"
              >
                <b-form-file
                  ref="file_kak_1"
                  v-model="file2[0]"
                  name="file_kak_1"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col
              v-if="fileUri[0]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-link
                  v-b-tooltip.hover.top="fileUri[0].substring(fileUri[0].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri[0])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                </b-link>
              </p>
            </b-col>
            <b-col>
              <b-form-group
                v-b-tooltip.hover.top="'Upload Daftar Data Spasial (dalam format .xls atau .csv)'"
                label="Upload Daftar Data Spasial (dalam format .xls atau .csv)"
                label-for="file_1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Daftar Data Spasial"
                  :rules="(!fileUri[0]) ? 'required' : ''"
                >
                  <b-form-file
                    ref="file_1"
                    v-model="file1[0]"
                    name="file_1"
                    :required="(!fileUri[0]) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link
                  href="/panduan/form_daftar_data_spasial_clearance_spba_ta_2024.xlsx"
                  target="_blank"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="12"
                  /> Contoh Form Daftar Data Spasial
                </b-link>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-if="jenis_data[1]"
        class="mb-2"
      >
        <b-col cols="12">
          <hr>
          <h5 class="my-1">
            Belanja Data Statistik
          </h5>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            label="Kategori Jenis Belanja Data Statistik"
            label-for="vi-jenis_data_statistik"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Jenis Belanja Data Statistik"
              rules="required"
            >
              <v-select
                id="vi-jenis_data_statistik"
                v-model="kategoriJenisData[1]"
                required
                name="jenis_data_statistik"
                :options="daftarJenisDataStatistik"
                placeholder="Kategori Jenis Belanja Data Statistik"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Unit yang berperan sebagai produsen Data Statistik (Satuan Kerja Eselon II).'"
            label="Produsen Data Statistik (Satuan Kerja Eselon II)"
            label-for="vi-produsen_spasial_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Produsen Data Statistik (Satuan Kerja Eselon II)"
              rules="required"
            >
              <v-select
                id="vi-produsen_spasial_2"
                v-model="produsen[1]"
                required
                name="produsen_spasial_2"
                :options="referenceData.ref_eselon_dua"
                placeholder="Produsen Data Statistik"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Aplikasi yang berperan sebagai pendukung / pengumpulan Data Statistik.'"
            label="Aplikasi Pendukung/Pengumpulan Data (Statistik)"
            label-for="vi-aplikasi_spasial_2"
          >
            <b-form-input
              id="vi-aplikasi_spasial_2"
              v-model="aplikasi[1]"
              name="aplikasi_spasial_2"
              placeholder="Aplikasi Pendukung/Pengumpulan Data (Statistik)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
            label="Sifat Data (Statistik)"
            label-for="vi-data_status_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Sifat Data (Statistik)"
              rules="required"
            >
              <v-select
                id="vi-data_status_2"
                v-model="sifat[1]"
                required
                name="data_status_2"
                :options="referenceData.ref_sifat_data"
                placeholder="Sifat Data (Statistik)"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran[1] !== '') ? ': Rp. ' + Number(total_anggaran[1]).toLocaleString() : ''}`"
            label-for="vi-total_anggaran_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran_2"
                v-model="total_anggaran[1]"
                type="number"
                name="total_anggaran_2"
                required
                placeholder="Total Anggaran"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-row>
            <b-col
              v-if="fileUri2[1]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-button
                  variant="success"
                  size="sm"
                  class="p-25"
                  @click="viewFile(fileUri2[1])"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-b-tooltip.hover.top="fileUri2[1].substring(fileUri2[1].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri2[1])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                </b-link>
              </p>
            </b-col>
            <b-col>
              <b-form-group
                v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf), mohon dilengkapi dengan mekanisme penyelenggaraan IGT dan status pemenuhan SDI'"
                label="Kerangka Acuan Kerja (dalam format PDF)"
                label-for="file_kak_2"
              >
                <b-form-file
                  ref="file_kak_2"
                  v-model="file2[1]"
                  name="file_kak_2"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6' && kategoriJenisData[1] && kategoriJenisData[1].kategori_jenis_data_id < 6"
          md="12"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Apakah Sudah Memiliki Surat Rekomendasi BPS'"
            label="Sudah Memiliki Surat Rekomendasi BPS?"
            label-for="vi-is_mou"
          >
            <validation-provider
              #default="{ errors }"
              name="Sudah Memiliki Surat Rekomendasi BPS?"
              rules="required"
            >
              <b-form-checkbox
                v-model="is_bps"
                switch
                inline
                class="mt-1"
              /> {{ (is_bps) ? 'Sudah' : 'Belum' }}
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col
              v-if="fileUri[1]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-link
                  v-b-tooltip.hover.top="fileUri[1].substring(fileUri[1].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri[1])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  /></b-link>
              </p>
            </b-col>
            <b-col
              v-if="is_bps && kategoriJenisData[1] && kategoriJenisData[1].kategori_jenis_data_id < 6"
            >
              <b-form-group
                v-b-tooltip.hover.top="`Upload file Surat Rekomendasi BPS (dalam format .pdf)`"
                label="Surat Rekomendasi BPS (dalam format PDF)"
                label-for="file_extra_doc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Surat Rekomendasi BPS"
                  :rules="(!fileUri[1]) ? 'required' : ''"
                >
                  <b-form-file
                    ref="file_extra_doc"
                    v-model="file1[1]"
                    name="file_extra_doc"
                    :required="(!fileUri[1]) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-else
            >
              <b-form-group
                v-b-tooltip.hover.top="'Upload Daftar Data Statistik (dalam format .xls atau .csv)'"
                label="Upload Daftar Data Statistik (dalam format .xls atau .csv)"
                label-for="file_2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Daftar Data Statistik"
                  :rules="(!fileUri[1]) ? 'required' : ''"
                >
                  <b-form-file
                    ref="file_2"
                    v-model="file1[1]"
                    name="file_2"
                    :required="(!fileUri[1]) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link
                  href="/panduan/form_daftar_data_statistik_clearance_spba_ta_2024.xlsx"
                  target="_blank"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="12"
                  /> Contoh Form Daftar Data Statistik
                </b-link>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6' && !is_bps && kategoriJenisData[1] && kategoriJenisData[1].kategori_jenis_data_id < 6"
          md="6"
        >
          <b-row>
            <b-col
              v-if="fileUri3[1]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-button
                  variant="success"
                  size="sm"
                  class="p-25"
                  @click="viewFile(fileUri3[1])"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-b-tooltip.hover.top="fileUri3[1].substring(fileUri3[1].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri3[1])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                </b-link>
              </p>
            </b-col>
            <b-col>
              <b-form-group
                v-b-tooltip.hover.top="'Upload Formulir Rekomendasi (dalam format .pdf)'"
                label="Upload Formulir Rekomendasi Statistik (dalam format .pdf)"
                label-for="file_4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Formulir Rekomendasi Statistik"
                  :rules="(!fileUri3[1]) ? 'required' : ''"
                >
                  <b-form-file
                    ref="file_4"
                    v-model="file3"
                    name="file_4"
                    :required="(!fileUri3[1]) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-if="jenis_data[2]"
        class="mb-2"
      >
        <b-col cols="12">
          <hr>
          <h5 class="my-1">
            Belanja Data Lainnya (Selain Data Statistik dan Data Geospasial)
          </h5>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            label="Kategori Jenis Belanja Data Lainnya"
            label-for="vi-jenis_data_lainnya"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Jenis Belanja Data Lainnya"
              rules="required"
            >
              <v-select
                id="vi-jenis_data_lainnya"
                v-model="kategoriJenisData[2]"
                required
                name="jenis_data_lainnya"
                :options="daftarJenisDataLainnya"
                placeholder="Kategori Jenis Belanja Data Lainnya"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Unit yang berperan sebagai produsen Data Lainnya (Satuan Kerja Eselon II).'"
            label="Produsen Data Lainnya (Satuan Kerja Eselon II)"
            label-for="vi-produsen_spasial_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Produsen Data Lainnya (Satuan Kerja Eselon II)"
              rules="required"
            >
              <v-select
                id="vi-produsen_spasial_3"
                v-model="produsen[2]"
                required
                name="produsen_spasial_3"
                :options="referenceData.ref_eselon_dua"
                placeholder="Produsen Data Lainnya"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Aplikasi yang berperan sebagai pendukung / pengumpulan Data Lainnya.'"
            label="Aplikasi Pendukung/Pengumpulan Data (Lainnya)"
            label-for="vi-aplikasi_spasial_3"
          >
            <b-form-input
              id="vi-aplikasi_spasial_3"
              v-model="aplikasi[2]"
              name="aplikasi_spasial_3"
              placeholder="Aplikasi Pendukung/Pengumpulan Data (Lainnya)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
            label="Sifat Data (Lainnya)"
            label-for="vi-data_status_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Sifat Data (Lainnya)"
              rules="required"
            >
              <v-select
                id="vi-data_status_3"
                v-model="sifat[2]"
                required
                name="data_status_3"
                :options="referenceData.ref_sifat_data"
                placeholder="Sifat Data (Lainnya)"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran[2] !== '') ? ': Rp. ' + Number(total_anggaran[2]).toLocaleString() : ''}`"
            label-for="vi-total_anggaran_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran_3"
                v-model="total_anggaran[2]"
                type="number"
                name="total_anggaran_3"
                required
                placeholder="Total Anggaran"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="coreData.jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-row>
            <b-col
              v-if="fileUri2[2]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-button
                  variant="success"
                  size="sm"
                  class="p-25"
                  @click="viewFile(fileUri2[2])"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-b-tooltip.hover.top="fileUri2[2].substring(fileUri2[2].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri2[2])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                </b-link>
              </p>
            </b-col>
            <b-col>
              <b-form-group
                v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf), mohon dilengkapi dengan mekanisme penyelenggaraan IGT dan status pemenuhan SDI'"
                label="Kerangka Acuan Kerja (dalam format PDF)"
                label-for="file_kak_3"
              >
                <b-form-file
                  ref="file_kak_3"
                  v-model="file2[2]"
                  name="file_kak_3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col
              v-if="fileUri[2]"
              cols="2"
            >
              <p>Dokumen<br>
                <b-link
                  v-b-tooltip.hover.top="fileUri[2].substring(fileUri[2].lastIndexOf('/') + 1)"
                  :href="getDoc(fileUri[2])"
                  class="btn btn-sm btn-info ml-25 p-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                </b-link>
              </p>
            </b-col>
            <b-col>
              <b-form-group
                v-b-tooltip.hover.top="'Upload Daftar Data Lainnya (dalam format .xls atau .csv)'"
                label="Upload Daftar Data Lainnya (dalam format .xls atau .csv)"
                label-for="file_3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Daftar Data Lainnya"
                  :rules="(!fileUri[2]) ? 'required' : ''"
                >
                  <b-form-file
                    ref="file_3"
                    v-model="file1[2]"
                    name="file_3"
                    :required="(!fileUri[2]) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link
                  href="/panduan/form_daftar_data_lainnya_clearance_spba_ta_2024.xlsx"
                  target="_blank"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="12"
                  /> Contoh Form Daftar Data Lainnya
                </b-link>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormDatepicker, BFormGroup, BFormTextarea, VBTooltip,
  BFormCheckbox, BInputGroup, BInputGroupAppend, BButton, BFormFile, BLink,
  BModal, BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import VuePdfApp from 'vue-pdf-app'
import AlertModuleDetail from '../detail/AlertModuleDetail.vue'
import NestedSiaDetail from '../detail/NestedSia.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BModal,
    BCardText,
    BLink,
    ValidationProvider,
    vSelect,
    AlertModuleDetail,
    BListGroup,
    BListGroupItem,
    VuePdfApp,
    NestedSiaDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    siaGuid: {
      type: String,
      default: '',
    },
    siaKegiatanGuid: {
      type: String,
      default: '',
    },
    siaMapData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      dataData: { is_verified: '' },
      clearanceStatus: null,
      fileDoc: '',
      referenceData: {
        data_informasi_list: [],
        ref_data_pokok: [],
        ref_data_tematik: [],
        ref_data_topic: [],
        ref_sub_data_topic: [],
        ref_sifat_data: [],
        ref_kategori_jenis_data: [],
        ref_eselon_dua: [],
        ref_document_rujukan_data: [],
      },
      data_utama_id: '',
      master_data: '',
      data_basic_id: '',
      data_tematic_id: '',
      data_topic_id: '',
      sub_data_topic_id: '',
      data_interoperability_id: '',
      data_status_id: '',
      daftarDataInteroperability: [
        { value: '0', nama: 'Tidak' },
        { value: '1', nama: 'Ya' },
      ],
      daftarDataTematik: [],
      daftarDataTopik: [],
      daftarDataSubTopik: [],
      daftarMasterDataInformasi: { data_pokok_id: 'new', label: 'Tambah Data dan Informasi Baru' },
      daftarMasterTopik: { data_topic_id: 'new', nama: 'Tambah Fungsi Pemerintahan Baru' },
      daftarMasterSubTopik: { sub_data_topic_id: 'new', nama: 'Tambah Sub Fungsi Pemerintahan Baru' },
      is_bps: false,
      total_anggaran: [null, null, null],
      file1: [null, null, null],
      file2: [null, null, null],
      file3: null,
      jenis_data: [null, null, null],
      produsen: ['', '', ''],
      aplikasi: ['', '', ''],
      sifat: [null, null, null],
      kategoriJenisData: [null, null, null],
      fileUri: [null, null, null, null],
      fileUri2: [null, null, null],
      fileUri3: [null, null, null],
      daftarJenisDataSpasial: [],
      daftarJenisDataStatistik: [],
      daftarJenisDataLainnya: [],
      daftarDataRujukan: [],
      fungsi_and_sub: false,
      sub_fungsi: false,
      data_topic_nama: '',
      sub_data_topic_nama: '',
      rujukan_select: [],
      rujukan_select_dtl: {},
      rujukan_regulasi: '',
      rujukan_file: [],
      rujukan_file_uri: '',
      rujukan_lainnya: false,
      dokumen_rujukan: [],
      siaLoad: false,
      siaFailed: false,
      siaFailedMsg: '',
      siaFailedLink: false,
      siaItemsLists: [],
      siaItems: {},
    }
  },
  watch: {
    actionStep() {
      this.checkInformasi()
    },
    siaGuid() {
      // this.getSiaData()
    },
    siaKegiatanGuid() {
      // this.getSiaData()
    },
    siaMapData() {
      this.inputSia()
    },
  },
  created() {
    this.data_utama_id = Number(this.$route.params.id)
  },
  mounted() {
    this.getReference()
  },
  methods: {
    inputSia() {
      if (Object.keys(this.siaMapData).length > 0) {
        this.siaLoad = true
        document.getElementById('loading-bg').style.display = 'block'

        this.dataData.nama = this.siaMapData.name
        this.dataData.tujuan = this.siaMapData.tujuan
        this.dataData.pic_name = this.siaMapData.penanggung_jawab
        this.dataData.deskripsi = this.siaMapData.uraian

        this.daftarDataInteroperability.forEach(entry => {
          if (this.siaMapData.interoperabilitas === entry.nama) {
            this.data_interoperability_id = entry
          }
        })
        this.referenceData.ref_sifat_data.forEach(entry => {
          if (this.siaMapData.sifat === entry.nama) {
            this.data_status_id = entry
          }
        })
        this.referenceData.ref_data_pokok.forEach(entry => {
          if (this.siaMapData.data_pokok.indexOf(entry.nama.toUpperCase()) === 0) {
            this.data_basic_id = entry
            this.getDataTematik()
          }
        })
        this.referenceData.ref_data_tematik.forEach(entry => {
          if (this.siaMapData.data_tematik.indexOf(entry.nama.toUpperCase()) === 0) {
            this.data_tematic_id = entry
            this.getDataTopik()
          }
        })
        this.referenceData.ref_data_topic.forEach(entry => {
          if (this.siaMapData.data_topik.indexOf(entry.nama.toUpperCase()) === 0) {
            this.data_topic_id = entry
            this.getDataSubTopik()
          }
        })
        if (this.siaMapData.jenis_data) {
          if (this.siaMapData.jenis_data === 'Data Statistik') {
            this.jenis_data[0] = true
            this.sifat[0] = this.siaMapData.sifat
          } else if (this.siaMapData.jenis_data === 'Data Spasial') {
            this.jenis_data[1] = true
            this.sifat[1] = this.siaMapData.sifat
          } else if (this.siaMapData.jenis_data === 'Data Lainnya') {
            this.jenis_data[2] = true
            this.sifat[2] = this.siaMapData.sifat
          }
        }

        document.getElementById('loading-bg').style.display = 'none'
        this.siaLoad = false
      }
    },
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    getReference() {
      this.$http.get('/clearance/data-informasi/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.data_informasi_list) {
              const pics = [this.daftarMasterDataInformasi]
              res.data.data.data_informasi_list.map(value => {
                const pic = value
                pic.label = `${value.nama} / ${value.data_pokok} / ${value.data_tematik}`
                pics.push(pic)
                return true
              })
              this.referenceData.data_informasi_list = pics
            }
            if (res.data.data.ref_kategori_jenis_data) {
              res.data.data.ref_kategori_jenis_data.map(value => {
                if (value.jenis_data_id === 1) {
                  this.daftarJenisDataSpasial.push(value)
                }
                if (value.jenis_data_id === 2) {
                  this.daftarJenisDataStatistik.push(value)
                }
                if (value.jenis_data_id === 3) {
                  this.daftarJenisDataLainnya.push(value)
                }
                return true
              })
            }
            if (res.data.data.ref_document_rujukan_data) {
              res.data.data.ref_document_rujukan_data.map(value => {
                this.daftarDataRujukan[value.nama] = value.childreen
                this.rujukan_select_dtl[value.nama] = []
                return true
              })
            }
            this.setReference()
          }
        })
    },
    setSelected() {
      if (this.master_data.data_informasi_id !== 'new') {
        this.dataData = this.master_data

        this.nama = this.dataData.nama
        this.tujuan = this.dataData.tujuan
        this.valid_start = this.dataData.valid_start
        this.valid_end = this.dataData.valid_end
        this.pic_name = this.dataData.pic_name
        this.deskripsi = this.dataData.deskripsi
        this.walidata = this.dataData.walidata

        this.data_basic_id = {
          data_pokok_id: this.dataData.data_pokok_id,
          nama: this.dataData.data_pokok,
        }
        this.getDataTematik()

        if (this.dataData.data_tematik_id) {
          this.data_tematic_id = {
            data_tematik_id: this.dataData.data_tematik_id,
            nama: this.dataData.data_tematik,
          }
          this.getDataTopik()
        }

        if (this.dataData.data_topic_id) {
          this.data_topic_id = {
            data_topic_id: this.dataData.data_topic_id,
            nama: this.dataData.data_topic,
          }
          this.getDataSubTopik()
        }

        if (this.dataData.sub_data_topic_id) {
          this.sub_data_topic_id = {
            sub_data_topic_id: this.dataData.sub_data_topic_id,
            nama: this.dataData.sub_data_topic,
          }
        }

        this.data_status_id = {
          sifat_data_id: this.dataData.sifat_data_id,
          nama: this.dataData.sifat_data,
        }

        this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
      }
    },
    setReference() {
      this.$http.get('/clearance/data-informasi', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataData = res.data.data

            this.data_basic_id = {
              data_pokok_id: this.dataData.data_pokok_id,
              nama: this.dataData.data_pokok,
            }
            this.getDataTematik()

            if (this.dataData.data_tematik_id) {
              this.data_tematic_id = {
                data_tematik_id: this.dataData.data_tematik_id,
                nama: this.dataData.data_tematik,
              }
              this.getDataTopik()
            }

            if (this.dataData.data_topic_id) {
              this.data_topic_id = {
                data_topic_id: this.dataData.data_topic_id,
                nama: this.dataData.data_topic,
              }
              this.getDataSubTopik()
            }

            if (this.dataData.sub_data_topic_id) {
              this.sub_data_topic_id = {
                sub_data_topic_id: this.dataData.sub_data_topic_id,
                nama: this.dataData.sub_data_topic,
              }
            }

            if (this.dataData.data_items) {
              this.dataData.data_items.map(dt => {
                const idx = dt.jenis_data_id - 1

                this.jenis_data[idx] = true
                this.aplikasi[idx] = dt.aplikasi_sumber
                this.total_anggaran[idx] = dt.total_anggaran
                this.fileUri[idx] = dt.document_uri
                this.fileUri2[idx] = dt.document_kak
                if (dt.document_formulir) {
                  this.fileUri3[idx] = dt.document_formulir
                }

                this.referenceData.ref_eselon_dua.map(contact => {
                  if (contact.instansi_unit_id === dt.instansi_unit_id) {
                    this.produsen[idx] = contact
                  }
                  return true
                })
                this.referenceData.ref_sifat_data.map(contact => {
                  if (contact.sifat_data_id === dt.sifat_data_id) {
                    this.sifat[idx] = contact
                  }
                  return true
                })
                this.referenceData.ref_kategori_jenis_data.map(contact => {
                  if (contact.kategori_jenis_data_id === dt.kategori_jenis_data_id) {
                    this.kategoriJenisData[idx] = contact
                  }
                  return true
                })

                return true
              })
            }

            if (this.dataData.dok_rujukan) {
              this.rujukan_select = []
              this.dataData.dok_rujukan.map(dt => {
                if (!this.rujukan_select.includes(dt.nama_dokumen)) {
                  this.rujukan_select.push(dt.nama_dokumen)
                }
                if (dt.dokumen_rujukan_id === 1) {
                  this.rujukan_regulasi = dt.keterangan
                  this.rujukan_file_uri = dt.document_uri
                }
                if (dt.nama) {
                  this.rujukan_select_dtl[dt.nama_dokumen].push({
                    dokumen_rujukan_id: dt.dokumen_rujukan_id,
                    nama: dt.nama,
                  })
                }
                return true
              })
            }

            this.data_status_id = {
              sifat_data_id: this.dataData.sifat_data_id,
              nama: this.dataData.sifat_data,
            }
            this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
          }
        })
    },
    getDataTematik() {
      this.daftarDataTematik.splice(0)
      this.data_tematic_id = ''

      const filterVal = this.data_basic_id.data_pokok_id
      const refData = this.referenceData.ref_data_tematik
      refData.map(contact => {
        if (contact.data_pokok_id === filterVal) {
          this.daftarDataTematik.push(contact)
        }
        return true
      })
    },
    getDataTopik() {
      this.daftarDataTopik = [this.daftarMasterTopik]
      this.data_topic_id = ''

      const filterVal = this.data_tematic_id.data_tematik_id
      const refData = this.referenceData.ref_data_topic
      refData.map(contact => {
        if (contact.data_tematik_id === filterVal) {
          this.daftarDataTopik.push(contact)
        }
        return true
      })

      if (this.daftarDataTopik.length === 1) {
        this.fungsi_and_sub = true
        this.sub_fungsi = true
      }
    },
    getDataSubTopik() {
      this.daftarDataSubTopik = [this.daftarMasterSubTopik]
      this.sub_data_topic_id = ''

      const filterVal = this.data_topic_id.data_topic_id
      const refData = this.referenceData.ref_sub_data_topic
      refData.map(contact => {
        if (contact.data_topic_id === filterVal) {
          this.daftarDataSubTopik.push(contact)
        }
        return true
      })

      if (this.data_topic_id && this.data_topic_id.data_topic_id === 'new') {
        this.fungsi_and_sub = true
      }

      if (this.daftarDataSubTopik.length === 1) {
        this.sub_fungsi = true
      }
    },
    setSubFungsiGov() {
      if (this.sub_data_topic_id && this.sub_data_topic_id.sub_data_topic_id === 'new') {
        this.sub_fungsi = true
      }
    },
    unitReset(val) {
      if (val === 'cat') {
        this.fungsi_and_sub = false
        this.sub_fungsi = false
        this.data_topic_nama = ''
        this.sub_data_topic_nama = ''
      }
      if (val === 'sub') {
        this.sub_fungsi = false
        this.sub_data_topic_nama = ''
      }
    },
    saveInformasi() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.data_utama_id,
        instansi_id: this.dataData.instansi_id,
        data_pokok_id: this.data_basic_id ? this.data_basic_id.data_pokok_id : 1,
        data_tematik_id: this.data_tematic_id ? this.data_tematic_id.data_tematik_id : 1,
        nama: this.dataData.nama,
        interoperability_id: this.data_interoperability_id.value,
        sifat_data_id: this.data_status_id.sifat_data_id,
        tujuan: this.dataData.tujuan,
        valid_start: this.dataData.valid_start,
        valid_end: this.dataData.valid_end,
        pic_name: this.dataData.pic_name,
        deskripsi: this.dataData.deskripsi,
        walidata: this.dataData.walidata,
        tahun_anggaran: this.coreData.tahun_anggaran,
      }
      if (this.dataData.data_informasi_id) {
        metaUtama.data_informasi_id = this.dataData.data_informasi_id
      }
      if (this.data_topic_id && this.data_topic_id.data_topic_id !== 'new') {
        metaUtama.data_topic_id = this.data_topic_id.data_topic_id
      }
      if (this.sub_data_topic_id && this.sub_data_topic_id.sub_data_topic_id !== 'new') {
        metaUtama.sub_data_topic_id = this.sub_data_topic_id.sub_data_topic_id
      }
      if (this.data_topic_nama) {
        metaUtama.data_topic_nama = this.data_topic_nama
      }
      if (this.sub_data_topic_nama) {
        metaUtama.sub_data_topic_nama = this.sub_data_topic_nama
      }
      if (this.dokumen_rujukan.length !== 0) {
        metaUtama.dokumen_rujukan = this.dokumen_rujukan
      }
      this.$http.post('/clearance/data-informasi', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          this.data_informasi_id = res.data.data.data_informasi_id
          if (Number(this.coreData.jenis_pengadaan_id) === 3 || Number(this.coreData.jenis_pengadaan_id) === 6) {
            if (this.rujukan_lainnya) {
              this.saveRujukanLainnya()
            } else {
              this.saveJenisData()
            }
          } else {
            const data = {
              status: true,
              msg: res.data.data,
            }
            data.msg.data_utama_id = this.clearanceId
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    setRujukan() {
      this.dokumen_rujukan = []
      this.rujukan_lainnya = false
      this.rujukan_select.map(dt => {
        if (dt === 'Lainnya') {
          this.rujukan_lainnya = true
          this.dokumen_rujukan.push(1)
        } else if (this.daftarDataRujukan[dt].length === 1) {
          this.dokumen_rujukan.push(this.daftarDataRujukan[dt][0].dokumen_rujukan_id)
        } else if (this.rujukan_select_dtl[dt] && this.rujukan_select_dtl[dt].length !== 0) {
          this.rujukan_select_dtl[dt].map(dp => {
            this.dokumen_rujukan.push(dp.dokumen_rujukan_id)
            return true
          })
        }
        return true
      })
      return true
    },
    checkRujukan() {
      this.setRujukan()
      if (this.rujukan_select.includes('Lainnya') && !this.rujukan_regulasi && this.rujukan_file.length === 0) {
        const data = {
          status: false,
          msg: 'Dokumen Rujukan Lainnya Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
        return false
      } if (this.dokumen_rujukan.length === 0) {
        const data = {
          status: false,
          msg: 'Dokumen Rujukan Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
        return false
      }
      return true
    },
    checkInformasi() {
      if (this.checkRujukan()) {
        let jenisCheck = false

        this.jenis_data.map(dt => {
          if (dt === true) {
            jenisCheck = true
          }
          return true
        })

        if (!jenisCheck && (Number(this.coreData.jenis_pengadaan_id) === 3 || Number(this.coreData.jenis_pengadaan_id) === 6)) {
          const data = {
            status: false,
            msg: 'Jenis Data yang Dikumpulkan/Dihasilkan Tidak Boleh Kosong',
          }
          this.$emit('step-completed', data)
        } else {
          this.saveInformasi()
        }
      }
    },
    saveJenisData() {
      const formFile = new FormData()
      let c = 0
      let send = true
      this.jenis_data.map((dt, idx) => {
        if (dt === true) {
          formFile.append(`datas[${c}][jenis_data_id]`, idx + 1)
          if (this.sifat[idx] === null) {
            send = false
          } else {
            formFile.append(`datas[${c}][sifat_data_id]`, this.sifat[idx].sifat_data_id)
          }
          if (this.produsen[idx] === null) {
            send = false
          } else {
            formFile.append(`datas[${c}][instansi_unit_id]`, this.produsen[idx].instansi_unit_id)
          }
          formFile.append(`datas[${c}][aplikasi_sumber]`, this.aplikasi[idx])
          formFile.append(`datas[${c}][total_anggaran]`, this.total_anggaran[idx])
          if (this.file1[idx]) {
            formFile.append(`datas[${c}][doc]`, this.file1[idx])
          } else {
            formFile.append(`datas[${c}][doc]`, '')
          }
          if (this.file2[idx]) {
            formFile.append(`datas[${c}][kak]`, this.file2[idx])
          } else {
            formFile.append(`datas[${c}][kak]`, '')
          }
          if (idx === 1 && this.file3) {
            formFile.append(`datas[${c}][formulir]`, this.file3)
          } else {
            formFile.append(`datas[${c}][formulir]`, '')
          }
          if (this.kategoriJenisData[idx] && this.kategoriJenisData[idx].kategori_jenis_data_id) {
            formFile.append(`datas[${c}][kategori_jenis_data_id]`, this.kategoriJenisData[idx].kategori_jenis_data_id)
          }
          c += 1
        }
        return dt
      })

      if (!send) {
        const data = {
          status: false,
          msg: 'Periksa Kelengkapan Isian Jenis Data Anda',
        }
        this.$emit('step-completed', data)
        document.getElementById('loading-bg').style.display = 'none'
      } else if (c !== 0) {
        this.sendJenisData(formFile)
      }
    },
    sendJenisData(formFile) {
      this.$http.post('/clearance/data-informasi/add-item', formFile, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
          data_informasi_id: this.data_informasi_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          data.msg.data_utama_id = this.$route.params.id
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saveRujukanLainnya() {
      const formFile = new FormData()
      formFile.append('data_informasi_id', this.data_informasi_id)
      formFile.append('dokumen_rujukan_id', 1)
      formFile.append('nama_regulasi', this.rujukan_regulasi)
      formFile.append('dokumen_rujukan_file', this.rujukan_file)

      this.$http.post('/clearance/data-informasi/file/upload-dok-rujukan', formFile, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(() => {
          this.saveJenisData()
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
