<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Status kepemilikan dari data storage yang digunakan.'"
        label="Kepemilikan"
        label-for="vi-ownership_status"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama pemilik data storage yang digunakan.'"
        label="Nama Pemilik"
        label-for="vi-nama_pemilik"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik"
          rules="required"
        >
          <b-form-input
            id="vi-nama_pemilik"
            v-model="infData.nama_pemilik"
            required
            name="nama_pemilik"
            placeholder="Nama Pemilik"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Unit pengelola data storage yang digunakan.'"
        label="Unit Pengelola Perangkat Keras Media Penyimpanan"
        label-for="vi-unit_operasional"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Pengelola Perangkat Keras Media Penyimpanan"
          rules="required"
        >
          <b-form-input
            id="vi-unit_operasional"
            v-model="infData.unit_operasional"
            required
            name="unit_operasional"
            placeholder="Unit Pengelola Perangkat Keras Media Penyimpanan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Lokasi dari data storage yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'"
        label="Lokasi Perangkat Keras Media Penyimpanan"
        label-for="vi-lokasi"
      >
        <validation-provider
          #default="{ errors }"
          name="Lokasi Perangkat Keras Media Penyimpanan"
          rules="required"
        >
          <b-form-input
            id="vi-lokasi"
            v-model="infData.lokasi"
            required
            name="lokasi"
            placeholder="Lokasi Perangkat Keras Media Penyimpanan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Perangkat lunak yang digunakan oleh data storage.'"
        label="Perangkat Lunak yang digunakan"
        label-for="vi-perangkat_lunak"
      >
        <validation-provider
          #default="{ errors }"
          name="Perangkat Lunak yang digunakan"
          rules="required"
        >
          <b-form-input
            id="vi-perangkat_lunak"
            v-model="infData.perangkat_lunak"
            required
            name="perangkat_lunak"
            placeholder="Perangkat Lunak yang digunakan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jumlah kapasitas penyimpanan pada data storage, satuan kapasitas penyimpanan dalam Gigabyte (GB).'"
        label="Kapasitas Penyimpanan"
        label-for="vi-kapasitas_penyimpanan"
      >
        <validation-provider
          #default="{ errors }"
          name="Kapasitas Penyimpanan"
          rules="required"
        >
          <b-input-group append="GB">
            <b-form-input
              id="vi-kapasitas_penyimpanan"
              v-model="infData.kapasitas_penyimpanan"
              required
              name="kapasitas_penyimpanan"
              placeholder="Kapasitas Penyimpanan"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Metode akses data sharing yang digunakan pada data storage (Direct Attached Storage/DAS atau Network Attached Storage/NAS)'"
        label="Metode Akses Data Sharing"
        label-for="vi-metode_akses_penyimpanan"
      >
        <validation-provider
          #default="{ errors }"
          name="Metode Akses Data Sharing"
          rules="required"
        >
          <v-select
            id="vi-metode_akses_penyimpanan"
            v-model="infData.metode_akses_penyimpanan"
            required
            name="metode_akses_penyimpanan"
            :options="daftarJenisShare"
            placeholder="Metode Akses Data Sharing"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Berisi deskripsi dari data storage yang digunakan.'"
        label="Deskripsi Data Storage"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Data Storage"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Data Storage"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow, BFormTextarea, BInputGroup, BFormInput, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BFormTextarea,
    BInputGroup,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarDataDigunakan: [],
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisShare: ['Direct Attached Storage (DAS)', 'Network Attached Storage (NAS)'],
    }
  },
}
</script>
