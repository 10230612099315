<template>
  <validation-observer ref="formAplikasi">
    <b-form :id="`form-new-app-${newIndex}`">
      <b-card class="border-bottom">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Aplikasi
            </h5>
            <small class="text-muted">Referensi Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan kegiatan.</small>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Referensi Arsitektur Domain Aplikasi sesuai dengan Renja'"
              label-for="vi-master_aplikasi"
            >
              <validation-provider
                #default="{ errors }"
                name="Pilih Nama Aplikasi atau Tambah Baru"
                rules="required"
              >
                <v-select
                  id="vi-master_aplikasi"
                  v-model="master_aplikasi"
                  required
                  name="master_aplikasi"
                  :options="referenceData.aplikasi_list"
                  placeholder="Pilih Nama Aplikasi atau Tambah Baru"
                  label="label"
                  @input="setSelected()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="master_aplikasi !== ''">
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Domain Aplikasi"
              label-for="vi-app_domain_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Domain Aplikasi"
                rules="required"
              >
                <v-select
                  id="vi-app_domain_id"
                  v-model="app_domain_id"
                  required
                  name="app_domain_id"
                  :options="referenceData.ref_domain_aplikasi"
                  placeholder="Domain Aplikasi"
                  label="nama"
                  @input="getAreaAplikasi()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Area Aplikasi"
              label-for="vi-app_area_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Area Aplikasi"
                rules="required"
              >
                <v-select
                  id="vi-service_area_id"
                  v-model="app_area_id"
                  required
                  name="app_area_id"
                  :options="daftarAreaAplikasi"
                  placeholder="Area Aplikasi"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Nama aplikasi yang digunakan atau dimiliki.'"
              label="Nama Aplikasi"
              label-for="vi-nama_aplikasi"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Aplikasi"
                rules="required"
              >
                <b-form-input
                  id="vi-nama_aplikasi"
                  v-model="nama_aplikasi"
                  required
                  name="nama_aplikasi"
                  placeholder="Nama Aplikasi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Berisi keterangan fungsi dari aplikasi terhadap layanan yang didukung.'"
              label="Fungsi Aplikasi"
              label-for="vi-fungsi"
            >
              <validation-provider
                #default="{ errors }"
                name="Fungsi Aplikasi"
                rules="required"
              >
                <b-form-input
                  id="vi-fungsi"
                  v-model="fungsi"
                  required
                  name="fungsi"
                  placeholder="Fungsi Aplikasi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Server yang digunakan oleh aplikasi, pilihan server yang digunakan didapat dari metadata perangkat keras server.'"
              label="Server Aplikasi"
              label-for="vi-server"
            >
              <validation-provider
                #default="{ errors }"
                name="Server Aplikasi"
                rules="required"
              >
                <b-form-input
                  id="vi-server"
                  v-model="server"
                  required
                  name="server"
                  placeholder="Server Aplikasi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Berisi uraian atau deskripsi secara umum dari aplikasi.'"
              label="Uraian Aplikasi"
              label-for="vi-uraian_aplikasi"
            >
              <validation-provider
                #default="{ errors }"
                name="Uraian Aplikasi"
                rules="required"
              >
                <b-form-textarea
                  id="vi-uraian_aplikasi"
                  v-model="uraian_aplikasi"
                  rows="8"
                  required
                  name="uraian_aplikasi"
                  placeholder="Uraian Aplikasi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              variant="outline-success"
              class="mt-0 mt-md-2"
              @click="simpanAplikasi"
            >
              <span>Simpan Aplikasi</span>
            </b-button>
            <b-button
              v-if="dataSaved"
              variant="flat-success"
              size="sm"
              class="mt-0 mt-md-2 ml-50"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="14"
              /> Aplikasi Berhasil Disimpan
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BForm, BCard, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, VBTooltip, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appData: {
      type: Object,
      default: () => {},
    },
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
    referenceData: {
      type: Object,
      default: () => {},
    },
    newIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      errorMsg: '',
      dataSaved: false,
      data_utama_id: '',
      aplikasi_id: '',
      master_aplikasi: '',
      daftarMasterAplikasi: { aplikasi_id: 'new', label: 'Tambah Referensi Aplikasi Baru' },
      daftarAreaAplikasi: [],
      aplikasiData: '',
      app_domain_id: '',
      app_area_id: '',
      nama_aplikasi: '',
      fungsi: '',
      server: '',
      uraian_aplikasi: '',
    }
  },
  watch: {
    actionStep() {
      this.simpanAplikasi()
    },
    referenceData() {
      this.getReference()
    },
  },
  mounted() {
    this.getReference()
    // this.aplikasi_id = 832
  },
  methods: {
    getReference() {
      // this.$http.get('/clearance/applications/app-list', {
      //   params: {
      //     tahun_anggaran: this.coreData.year_budget,
      //     token: localStorage.getItem('userToken'),
      //   },
      // })
      //   .then(res => {
      //     if (res.data.status === 'success') {
      //       this.referenceData = res.data.data

      //       if (res.data.data.aplikasi_list) {
      //         const pics = [this.daftarMasterAplikasi]
      //         res.data.data.aplikasi_list.map(value => {
      //           const pic = value
      //           pic.label = `${value.nama_aplikasi} / ${value.domain_aplikasi} / ${value.area_aplikasi}`
      //           pics.push(pic)
      //           return true
      //         })
      //         this.referenceData.aplikasi_list = pics
      //       }
      //     }
      //   })

      if (Object.keys(this.referenceData).length !== 0) {
        const pics = [this.daftarMasterAplikasi]
        this.referenceData.aplikasi_list.map(value => {
          const pic = value
          pic.label = `${value.nama_aplikasi} / ${value.domain_aplikasi} / ${value.area_aplikasi}`
          pics.push(pic)
          return true
        })
        this.referenceData.aplikasi_list = pics
      }
    },
    getAreaAplikasi() {
      this.daftarAreaAplikasi.splice(0)
      this.app_area_id = ''

      const filterVal = this.app_domain_id.domain_aplikasi_id
      const refData = this.referenceData.ref_area_aplikasi
      refData.map(contact => {
        if (contact.domain_aplikasi_id === filterVal) {
          this.daftarAreaAplikasi.push(contact)
        }
        return true
      })
    },
    setSelected() {
      if (this.master_aplikasi.aplikasi_id !== 'new') {
        this.aplikasiData = this.master_aplikasi

        this.nama_aplikasi = this.aplikasiData.nama_aplikasi
        this.fungsi = this.aplikasiData.fungsi
        this.server = this.aplikasiData.server
        this.uraian_aplikasi = this.aplikasiData.uraian_aplikasi

        this.app_domain_id = {
          domain_aplikasi_id: this.aplikasiData.domain_aplikasi_id,
          nama: this.aplikasiData.domain_aplikasi,
        }
        this.getAreaAplikasi()

        this.app_area_id = {
          area_aplikasi_id: this.aplikasiData.area_aplikasi_id,
          nama: this.aplikasiData.area_aplikasi,
        }
      } else {
        this.aplikasiData = ''

        this.nama_aplikasi = ''
        this.fungsi = ''
        this.server = ''
        this.uraian_aplikasi = ''
        this.app_domain_id = ''
        this.app_area_id = ''
      }
    },
    simpanAplikasi() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$refs.formAplikasi.validate()
        .then(success => {
          if (success) {
            this.saveAplikasi()
          } else {
            const data = {
              status: false,
              index: (this.appData.ubah) ? `new-${this.appData.id}` : this.appData.id,
              type: 'app',
              msg: 'Terdapat Kesalahan Validasi Aplikasi, Periksa Input Aplikasi Anda.',
            }
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          const data = {
            status: false,
            index: (this.appData.ubah) ? `new-${this.appData.id}` : this.appData.id,
            type: 'app',
            msg: error.message,
          }
          this.errorMsg = error.message
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saveAplikasi() {
      this.errorMsg = ''
      const metaUtama = {
        data_utama_id: this.clearanceId,
        instansi_id: this.userData.instansi_id,
        domain_aplikasi_id: this.app_domain_id.domain_aplikasi_id,
        area_aplikasi_id: this.app_area_id.area_aplikasi_id,
        nama_aplikasi: this.nama_aplikasi,
        fungsi: this.fungsi,
        server: this.server,
        uraian_aplikasi: this.uraian_aplikasi,
      }
      if (this.aplikasi_id !== '') {
        metaUtama.aplikasi_id = this.aplikasi_id
      }
      this.$http.post('/clearance/applications', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.clearanceId,
        },
      })
        .then(res => {
          this.aplikasi_id = res.data.data.aplikasi_id
          const data = {
            status: true,
            index: (this.appData.ubah) ? `new-${this.appData.id}` : this.appData.id,
            type: 'app',
            msg: res.data.data,
          }
          this.dataSaved = true
          this.errorMsg = ''
          data.msg.data_utama_id = this.clearanceId
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            index: (this.appData.ubah) ? `new-${this.appData.id}` : this.appData.id,
            type: 'app',
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
