<template>
  <b-col
    v-if="docData.is_verified_asdep || docData.is_verified_bapenas"
    cols="12"
  >
    <b-alert
      :variant="(docData.is_verified_asdep === 1 || docData.is_verified_bapenas === 1) ? 'success' : 'warning'"
      show
    >
      <div
        v-if="docData.is_verified_asdep === 1 || docData.is_verified_bapenas === 1"
        class="alert-body"
      >
        <span>Terverifikasi</span>
      </div>
      <h4
        v-if="docData.is_verified_asdep === -1 || docData.is_verified_bapenas === -1"
        class="alert-heading"
      >
        Catatan Perbaikan
      </h4>
      <div
        v-if="(docData.is_verified_asdep === -1 || docData.is_verified_bapenas === -1) && docData.keterangan !== ''"
        class="alert-body"
      >
        <span>{{ docData.keterangan }}</span>
      </div>
      <template v-if="dataUtama">
        <b-button
          v-if="userData.group_id === 100"
          variant="outline-info"
          class="mx-1 mb-1"
          :to="{ name: 'pengajuan-surat-ubah', params: { id: docData.clearance_id } }"
        >
          Ubah Surat
        </b-button>
      </template>
    </b-alert>
  </b-col>
</template>

<script>
import { BCol, BAlert, BButton } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BAlert,
    BCol,
    BButton,
  },
  props: {
    docData: {
      type: Object,
      default: () => {},
    },
    dataUtama: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { userData: getUserData() }
  },
}
</script>
