<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Proses Bisnis
        </h5>
        <small class="text-muted">Arsitektur Domain Proses Bisnis SPBE.</small>
      </b-col>
      <b-col
        v-if="saktiFailed"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            <span>Konektivitas dengan <u>Sakti</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi <u>Admin</u></span>
          </div>
        </b-alert>
      </b-col>
      <alert-module-detail :doc-data="bisnisData" />
      <b-col
        v-if="programUpdate"
        cols="12"
        class="mb-1"
      >
        <b-alert
          variant="info"
          show
        >
          <h4 class="alert-heading">
            Perhatian
          </h4>
          <div class="alert-body">
            <span>Nama Program disesuaikan dengan data <u>Sakti</u>, periksa kembali isian Domain Proses Bisnis Anda.</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="!bisnisData.nama_urusan"
        md="12"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Proses Bisnis sesuai dengan Renja'"
          label-for="vi-master_bisnis"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Urusan dan Nama Program atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_bisnis"
              v-model="master_bisnis"
              required
              name="master_bisnis"
              :options="referenceData.business_data_list"
              placeholder="Pilih Nama Urusan dan Nama Program atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Program sesuai dengan Renja'"
          label="Nama Program"
          label-for="vi-nama_program"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Program"
            rules="required"
          >
            <b-form-input
              id="vi-nama_program"
              v-model="bisnisData.nama_program"
              required
              name="nama_program"
              placeholder="Nama Program"
              :disabled="(saktiProgram !== '') ? true : false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Sektor Pemerintahan"
          label-for="vi-gov_sector_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sektor Pemerintahan"
            rules="required"
          >
            <v-select
              id="vi-gov_sector_id"
              v-model="gov_sector_id"
              required
              name="gov_sector_id"
              :options="referenceData.ref_sektor_pemerintahan"
              placeholder="Sektor Pemerintahan"
              label="nama"
              @input="getDaftarUrusan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Urusan Pemerintahan"
          label-for="vi-gov_affair_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Urusan Pemerintahan"
            rules="required"
          >
            <v-select
              id="vi-gov_affair_id"
              ref="gov_affair_id"
              v-model="gov_affair_id"
              required
              name="gov_affair_id"
              :options="daftarUrusan"
              placeholder="Urusan Pemerintahan"
              label="nama"
              @input="getFungsiGov"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Fungsi Pemerintahan"
          label-for="vi-gov_function"
        >
          <validation-provider
            #default="{ errors }"
            name="Fungsi Pemerintahan"
            rules="required"
          >
            <v-select
              v-if="!fungsi_and_sub"
              id="vi-gov_function"
              ref="gov_function"
              v-model="gov_function"
              required
              name="gov_function"
              :options="daftarFungsi"
              placeholder="Fungsi Pemerintahan"
              label="nama"
              @input="getSubFungsiGov"
            />
            <b-input-group
              v-if="fungsi_and_sub"
            >
              <b-form-input
                v-if="fungsi_and_sub"
                id="vi-gov_function"
                v-model="fungsi_pemerintahan_nama"
                required
                name="gov_function"
                placeholder="Fungsi Pemerintahan"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset('cat')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Sub Fungsi Pemerintahan"
          label-for="vi-gov_sub_function"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub Fungsi Pemerintahan"
            rules="required"
          >
            <v-select
              v-if="!sub_fungsi"
              id="vi-gov_sub_function"
              ref="gov_sub_function"
              v-model="gov_sub_function"
              required
              name="gov_sub_function"
              :options="daftarSubFungsi"
              placeholder="Sub Fungsi Pemerintahan"
              label="nama"
              @input="setSubFungsiGov"
            />
            <b-input-group
              v-if="sub_fungsi"
            >
              <b-form-input
                v-if="sub_fungsi"
                id="vi-gov_sub_function"
                v-model="sub_fungsi_pemerintahan_nama"
                required
                name="gov_sub_function"
                placeholder="Sub Fungsi Pemerintahan"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset('sub')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'"
          label="Nama Bisnis / Urusan"
          label-for="vi-nama_urusan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Bisnis / Urusan"
            rules="required"
          >
            <b-form-input
              id="vi-nama_urusan"
              v-model="bisnisData.nama_urusan"
              required
              name="nama_urusan"
              placeholder="Nama Bisnis / Urusan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Instansi yang mengajukan'"
          label="Instansi"
          label-for="vi-instansi_id"
        >
          <b-form-input
            v-model="bisnisData.instansi_id"
            hidden
            required
            name="instansi_id"
            placeholder="Instansi"
          />
          <b-form-input
            v-model="bisnisData.instansi"
            disabled
            placeholder="Instansi"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan uraian dari urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'"
          label="Uraian Bisnis / Urusan"
          label-for="vi-deskripsi_urusan"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Urusan"
            rules="required"
          >
            <b-form-textarea
              id="vi-deskripsi_urusan"
              v-model="bisnisData.deskripsi_urusan"
              rows="4"
              required
              name="deskripsi_urusan"
              placeholder="Uraian Bisnis / Urusan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BFormInput, BRow, BCol, BFormGroup, BFormTextarea, VBTooltip,
  BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import AlertModuleDetail from '../detail/AlertModuleDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    vSelect,
    AlertModuleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    sakti: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      data_utama_id: '',
      proses_bisnis_id: '',
      referenceData: {
        business_data_list: [],
        ref_sektor_pemerintahan: [],
        ref_urusan_pemerintahan: [],
        ref_fungsi_pemerintahan: [],
        ref_sub_fungsi_pemerintahan: [],
      },
      master_bisnis: '',
      bisnisData: {
        nama_program: '',
        nama_urusan: '',
        deskripsi_urusan: '',
        is_verified: '',
      },
      gov_sector_id: '',
      gov_affair_id: '',
      gov_function: '',
      gov_sub_function: '',
      daftarUrusan: [],
      daftarFungsi: [],
      daftarSubFungsi: [],
      daftarMasterBisnis: { proses_bisnis_id: 'new', label: 'Tambah Nama Bisnis / Nama Urusan Baru' },
      daftarMasterFungsi: { fungsi_pemerintahan_id: 'new', nama: 'Tambah Fungsi Pemerintahan Baru' },
      daftarMasterSubFungsi: { sub_fungsi_pemerintahan_id: 'new', nama: 'Tambah Sub Fungsi Pemerintahan Baru' },
      programUpdate: false,
      saktiFailed: false,
      saktiFailedMsg: '',
      saktiToken: '',
      saktiProgram: '',
      saktiItem: false,
      fungsi_and_sub: false,
      sub_fungsi: false,
      fungsi_pemerintahan_nama: '',
      sub_fungsi_pemerintahan_nama: '',
    }
  },
  watch: {
    actionStep() {
      this.saveBisnis()
    },
    sakti() {
      this.splpSaktiGetToken()
    },
  },
  mounted() {
    this.data_utama_id = Number(this.$route.params.id)
    this.bisnisData.instansi_id = this.userData.instansi_id
    this.bisnisData.instansi = this.userData.instansi_name
    this.getReference()
  },
  methods: {
    splpSaktiGetToken() {
      if (Object.keys(this.sakti).length !== 0) {
        this.saktiToken = store.state.app.saktiToken
        this.saktiGetProgram()
      }
    },
    saktiGetProgram() {
      if (Object.keys(this.sakti).length !== 0) {
        document.getElementById('loading-bg').style.display = 'block'

        const params = {
          year: this.coreData.tahun_anggaran,
          table: 'program',
          kddept: this.userData.instansi_kddept,
          kdunit: this.sakti.kdunit,
          kdgiat: this.sakti.kdgiat,
          kdprogram: this.sakti.kdprogram,
        }
        this.$splp.get('/ega', {
          headers: {
            Authorization: `Bearer ${this.saktiToken}`,
          },
          params,
        })
          .then(res => {
            if (res.data.success === true) {
              res.data.data.map(value => {
                this.saktiProgram = value
                return true
              })
              if (this.saktiProgram !== '') {
                this.nama_program = `${this.saktiProgram.kddept}.${this.saktiProgram.kdprogram}. ${this.saktiProgram.nmprogram}`
              }
              this.setSaktiReference()
              document.getElementById('loading-bg').style.display = 'none'
            } else {
              this.saktiFailed = true
              document.getElementById('loading-bg').style.display = 'none'
            }
          })
          .catch(error => {
            this.saktiFailedMsg = error
            this.saktiFailed = true
            document.getElementById('loading-bg').style.display = 'none'
          })
      }
    },
    getDaftarUrusan() {
      this.daftarUrusan = []
      this.gov_affair_id = ''

      if (this.gov_sector_id && this.gov_sector_id.sektor_pemerintahan_id) {
        const filterVal = this.gov_sector_id.sektor_pemerintahan_id
        const refUrusan = this.referenceData.ref_urusan_pemerintahan
        refUrusan.map(contact => {
          if (contact.sektor_pemerintahan_id === filterVal) {
            this.daftarUrusan.push(contact)
          }
          return true
        })
      }
    },
    getFungsiGov() {
      this.daftarFungsi = [this.daftarMasterFungsi]
      this.gov_function = ''

      if (this.gov_affair_id && this.gov_affair_id.urusan_pemerintahan_id) {
        const filterFVal = this.gov_affair_id.urusan_pemerintahan_id
        const refFungsi = this.referenceData.ref_fungsi_pemerintahan
        refFungsi.map(contact => {
          if (contact.urusan_pemerintahan_id === filterFVal) {
            this.daftarFungsi.push(contact)
          }
          return true
        })
      }

      if (this.daftarFungsi.length === 1) {
        this.fungsi_and_sub = true
        this.sub_fungsi = true
      }
    },
    getSubFungsiGov() {
      this.daftarSubFungsi = [this.daftarMasterSubFungsi]
      this.gov_sub_function = ''

      if (this.gov_function && this.gov_function.fungsi_pemerintahan_id !== 'new') {
        const filterSVal = this.gov_function.fungsi_pemerintahan_id
        const refSFungsi = this.referenceData.ref_sub_fungsi_pemerintahan
        refSFungsi.map(contact => {
          if (contact.fungsi_pemerintahan_id === filterSVal) {
            this.daftarSubFungsi.push(contact)
          }
          return true
        })
      }

      if (this.gov_function && this.gov_function.fungsi_pemerintahan_id === 'new') {
        this.fungsi_and_sub = true
      }

      if (this.daftarSubFungsi.length === 1) {
        this.sub_fungsi = true
      }
    },
    setSubFungsiGov() {
      if (this.gov_sub_function && this.gov_sub_function.sub_fungsi_pemerintahan_id === 'new') {
        this.sub_fungsi = true
      }
    },
    getReference() {
      this.$http.get('/clearance/business-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.business_data_list) {
              const pics = [this.daftarMasterBisnis]
              res.data.data.business_data_list.map(value => {
                const pic = value
                pic.label = `${value.nama_urusan} / ${value.nama_program} / ${value.sektor_pemerintahan} / ${value.urusan_pemerintahan}`
                pics.push(pic)
                return true
              })
              this.referenceData.business_data_list = pics
            }
            this.master_bisnis = this.daftarMasterBisnis
            this.setReference()
          }
        })
    },
    setSaktiReference() {
      const mapped = [this.daftarMasterBisnis]
      this.referenceData.business_data_list.map(value => {
        const pic = value
        if (value.nama_program === this.nama_program) {
          mapped.push(pic)
          this.programUpdate = true
        }
        return true
      })
      if (mapped.length !== 0) {
        this.referenceData.business_data_list = mapped
      }
      this.setReference()
    },
    setSelected() {
      if (this.master_bisnis.proses_bisnis_id !== 'new') {
        this.bisnisData = this.master_bisnis

        this.nama_program = this.bisnisData.nama_program
        this.nama_urusan = this.bisnisData.nama_urusan
        this.deskripsi_urusan = this.bisnisData.deskripsi_urusan

        this.gov_sector_id = {
          sektor_pemerintahan_id: this.bisnisData.sektor_pemerintahan_id,
          nama: this.bisnisData.sektor_pemerintahan,
        }
        this.getDaftarUrusan()

        if (this.bisnisData.urusan_pemerintahan_id) {
          this.gov_affair_id = {
            urusan_pemerintahan_id: this.bisnisData.urusan_pemerintahan_id,
            nama: this.bisnisData.urusan_pemerintahan,
          }
          this.getFungsiGov()
        }

        if (this.bisnisData.fungsi_pemerintahan_id) {
          this.gov_function = {
            fungsi_pemerintahan_id: this.bisnisData.fungsi_pemerintahan_id,
            nama: this.bisnisData.fungsi_pemerintahan,
          }
          this.getSubFungsiGov()
        }

        if (this.bisnisData.sub_fungsi_pemerintahan_id) {
          this.gov_sub_function = {
            sub_fungsi_pemerintahan_id: this.bisnisData.sub_fungsi_pemerintahan_id,
            nama: this.bisnisData.sub_fungsi_pemerintahan,
          }
        }
      }
    },
    unitReset(val) {
      if (val === 'cat') {
        this.fungsi_and_sub = false
        this.sub_fungsi = false
        this.fungsi_pemerintahan_nama = ''
        this.sub_fungsi_pemerintahan_nama = ''
      }
      if (val === 'sub') {
        this.sub_fungsi = false
        this.sub_fungsi_pemerintahan_nama = ''
      }
    },
    setReference() {
      this.$http.get('/clearance/business-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data.length !== 0) {
            [this.bisnisData] = res.data.data
            if (this.saktiProgram !== '') {
              this.bisnisData.nama_program = `${this.saktiProgram.kddept}.${this.saktiProgram.kdprogram}. ${this.saktiProgram.nmprogram}`
              this.programUpdate = true
            }

            this.gov_sector_id = {
              sektor_pemerintahan_id: this.bisnisData.sektor_pemerintahan_id,
              nama: this.bisnisData.sektor_pemerintahan,
            }
            this.getDaftarUrusan()

            if (this.bisnisData.urusan_pemerintahan_id) {
              this.gov_affair_id = {
                urusan_pemerintahan_id: this.bisnisData.urusan_pemerintahan_id,
                nama: this.bisnisData.urusan_pemerintahan,
              }
              this.getFungsiGov()
            }

            if (this.bisnisData.fungsi_pemerintahan_id) {
              this.gov_function = {
                fungsi_pemerintahan_id: this.bisnisData.fungsi_pemerintahan_id,
                nama: this.bisnisData.fungsi_pemerintahan,
              }
              this.getSubFungsiGov()
            }

            if (this.bisnisData.sub_fungsi_pemerintahan_id) {
              this.gov_sub_function = {
                sub_fungsi_pemerintahan_id: this.bisnisData.sub_fungsi_pemerintahan_id,
                nama: this.bisnisData.sub_fungsi_pemerintahan,
              }
            }
          }
        })

      if (this.saktiProgram !== '') {
        this.bisnisData.nama_program = `${this.saktiProgram.kddept}.${this.saktiProgram.kdprogram}. ${this.saktiProgram.nmprogram}`
        this.programUpdate = true
      }
    },
    saveBisnis() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.data_utama_id,
        nama_program: this.bisnisData.nama_program,
        sektor_pemerintahan_id: this.gov_sector_id.sektor_pemerintahan_id,
        urusan_pemerintahan_id: this.gov_affair_id.urusan_pemerintahan_id,
        nama_urusan: this.bisnisData.nama_urusan,
        instansi_id: this.bisnisData.instansi_id,
        deskripsi_urusan: this.bisnisData.deskripsi_urusan,
      }
      if (this.bisnisData.proses_bisnis_id) {
        metaUtama.proses_bisnis_id = this.bisnisData.proses_bisnis_id
      }
      if (this.gov_function && this.gov_function.fungsi_pemerintahan_id !== 'new') {
        metaUtama.fungsi_pemerintahan_id = this.gov_function.fungsi_pemerintahan_id
      }
      if (this.gov_sub_function && this.gov_sub_function.sub_fungsi_pemerintahan_id !== 'new') {
        metaUtama.sub_fungsi_pemerintahan_id = this.gov_sub_function.sub_fungsi_pemerintahan_id
      }
      if (this.fungsi_pemerintahan_nama) {
        metaUtama.fungsi_pemerintahan_nama = this.fungsi_pemerintahan_nama
      }
      if (this.sub_fungsi_pemerintahan_nama) {
        metaUtama.sub_fungsi_pemerintahan_nama = this.sub_fungsi_pemerintahan_nama
      }
      this.$http.post('/clearance/business-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
            sakti: this.saktiProgram,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
