import { render, staticRenderFns } from "./UbahKegiatanData.vue?vue&type=template&id=1efa5e2e"
import script from "./UbahKegiatanData.vue?vue&type=script&lang=js"
export * from "./UbahKegiatanData.vue?vue&type=script&lang=js"
import style0 from "./UbahKegiatanData.vue?vue&type=style&index=0&id=1efa5e2e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports