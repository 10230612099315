<template>
  <section id="ubah-data-kegiatan">
    <!--
      2. Aplikasi
      3. Infrastruktur
      6. Data
      7. Non Tekni
    -->
    <ubah-infrastruktur
      v-if="coreData.jenis_pengadaan_id == '2' && !idRenja"
      :core-data="coreData"
    />
    <ubah-aplikasi
      v-if="coreData.jenis_pengadaan_id == '3' && !idRenja"
      :core-data="coreData"
    />
    <ubah-data
      v-if="coreData.jenis_pengadaan_id == '6' && !idRenja"
      :core-data="coreData"
    />
    <ubah-non
      v-if="coreData.jenis_pengadaan_id == '7' && !idRenja"
      :core-data="coreData"
    />
    <ubah-krisna-infrastruktur
      v-if="coreData.jenis_pengadaan_id == '2' && idRenja"
      :core-data="coreData"
    />
    <ubah-krisna-aplikasi
      v-if="coreData.jenis_pengadaan_id == '3' && idRenja"
      :core-data="coreData"
    />
    <ubah-krisna-data
      v-if="coreData.jenis_pengadaan_id == '6' && idRenja"
      :core-data="coreData"
    />
    <ubah-krisna-non
      v-if="coreData.jenis_pengadaan_id == '7' && idRenja"
      :core-data="coreData"
    />
  </section>

</template>

<script>
import UbahData from './ubah/UbahKegiatanData.vue'
import UbahNon from './ubah/UbahKegiatanNon.vue'
import UbahAplikasi from './ubah/UbahKegiatanAplikasi.vue'
import UbahInfrastruktur from './ubah/UbahKegiatanInfrastruktur.vue'
import UbahKrisnaData from './ubah-krisna/UbahKegiatanData.vue'
import UbahKrisnaNon from './ubah-krisna/UbahKegiatanNon.vue'
import UbahKrisnaAplikasi from './ubah-krisna/UbahKegiatanAplikasi.vue'
import UbahKrisnaInfrastruktur from './ubah-krisna/UbahKegiatanInfrastruktur.vue'

export default {
  components: {
    UbahNon,
    UbahData,
    UbahAplikasi,
    UbahInfrastruktur,
    UbahKrisnaNon,
    UbahKrisnaData,
    UbahKrisnaAplikasi,
    UbahKrisnaInfrastruktur,
  },
  data() {
    return {
      data_utama_id: '',
      coreData: {},
      idRenja: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  created() {
    this.data_utama_id = Number(this.$route.params.id)
    this.$http.get('/clearance/core-data', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_utama_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.coreData = res.data.data
          this.idRenja = this.coreData.id_renja
        }
      })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
