<template>
  <ul>
    <li
      v-for="(value, key) in obj"
      :key="key"
    >
      <template v-if="Array.isArray(value)">
        <span class="font-weight-bolder">{{ key }}:</span>
        <ul>
          <li
            v-for="(item, index) in value"
            :key="index"
          >
            <template v-if="typeof item === 'object' && item !== null">
              <Nested-sia :obj="item" />
            </template>
            <template v-else>
              <span>{{ index }}: {{ item }}</span>
            </template>
          </li>
        </ul>
      </template>

      <template v-else-if="typeof value === 'object' && value !== null">
        <span class="font-weight-bolder">{{ key }}:</span>
        <ul>
          <Nested-sia :obj="value" />
        </ul>
      </template>

      <template v-else>
        <span class="font-weight-bolder">{{ key }}:</span> {{ value }}
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NestedSia',
  props: {
    obj: {
      type: [Object, Array],
      required: true,
    },
  },
}
</script>
