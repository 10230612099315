var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formAplikasi"},[_c('b-form',{attrs:{"id":("form-edit-app-" + (_vm.appData.id))}},[_c('b-card',{staticClass:"border-bottom"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Referensi Aplikasi ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Referensi Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan kegiatan.")])]),_c('alert-module-detail',{attrs:{"doc-data":_vm.appData}}),(!_vm.appData.nama_aplikasi)?_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Referensi Arsitektur Domain Aplikasi sesuai dengan Renja'),expression:"'Referensi Arsitektur Domain Aplikasi sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label-for":"vi-master_aplikasi"}},[_c('validation-provider',{attrs:{"name":"Pilih Nama Aplikasi atau Tambah Baru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_aplikasi","required":"","name":"master_aplikasi","options":_vm.referenceData.aplikasi_list,"placeholder":"Pilih Nama Aplikasi atau Tambah Baru","label":"label"},on:{"input":function($event){return _vm.setSelected()}},model:{value:(_vm.master_aplikasi),callback:function ($$v) {_vm.master_aplikasi=$$v},expression:"master_aplikasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1417303882)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Domain Aplikasi","label-for":"vi-app_domain_id"}},[_c('validation-provider',{attrs:{"name":"Domain Aplikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-app_domain_id","required":"","name":"app_domain_id","options":_vm.referenceData.ref_domain_aplikasi,"placeholder":"Domain Aplikasi","label":"nama"},on:{"input":function($event){return _vm.getAreaAplikasi()}},model:{value:(_vm.app_domain_id),callback:function ($$v) {_vm.app_domain_id=$$v},expression:"app_domain_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Area Aplikasi","label-for":"vi-app_area_id"}},[_c('validation-provider',{attrs:{"name":"Area Aplikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-service_area_id","required":"","name":"app_area_id","options":_vm.daftarAreaAplikasi,"placeholder":"Area Aplikasi","label":"nama"},model:{value:(_vm.app_area_id),callback:function ($$v) {_vm.app_area_id=$$v},expression:"app_area_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama aplikasi yang digunakan atau dimiliki.'),expression:"'Nama aplikasi yang digunakan atau dimiliki.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Aplikasi","label-for":"vi-nama_aplikasi"}},[_c('validation-provider',{attrs:{"name":"Nama Aplikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_aplikasi","required":"","name":"nama_aplikasi","placeholder":"Nama Aplikasi"},model:{value:(_vm.appData.nama_aplikasi),callback:function ($$v) {_vm.$set(_vm.appData, "nama_aplikasi", $$v)},expression:"appData.nama_aplikasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Berisi keterangan fungsi dari aplikasi terhadap layanan yang didukung.'),expression:"'Berisi keterangan fungsi dari aplikasi terhadap layanan yang didukung.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Fungsi Aplikasi","label-for":"vi-fungsi"}},[_c('validation-provider',{attrs:{"name":"Fungsi Aplikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-fungsi","required":"","name":"fungsi","placeholder":"Fungsi Aplikasi"},model:{value:(_vm.appData.fungsi),callback:function ($$v) {_vm.$set(_vm.appData, "fungsi", $$v)},expression:"appData.fungsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Server yang digunakan oleh aplikasi, pilihan server yang digunakan didapat dari metadata perangkat keras server.'),expression:"'Server yang digunakan oleh aplikasi, pilihan server yang digunakan didapat dari metadata perangkat keras server.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Server Aplikasi","label-for":"vi-server"}},[_c('validation-provider',{attrs:{"name":"Server Aplikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-server","required":"","name":"server","placeholder":"Server Aplikasi"},model:{value:(_vm.appData.server),callback:function ($$v) {_vm.$set(_vm.appData, "server", $$v)},expression:"appData.server"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Berisi uraian atau deskripsi secara umum dari aplikasi.'),expression:"'Berisi uraian atau deskripsi secara umum dari aplikasi.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Uraian Aplikasi","label-for":"vi-uraian_aplikasi"}},[_c('validation-provider',{attrs:{"name":"Uraian Aplikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-uraian_aplikasi","rows":"8","required":"","name":"uraian_aplikasi","placeholder":"Uraian Aplikasi"},model:{value:(_vm.appData.uraian_aplikasi),callback:function ($$v) {_vm.$set(_vm.appData, "uraian_aplikasi", $$v)},expression:"appData.uraian_aplikasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-success"},on:{"click":_vm.simpanAplikasi}},[_c('span',[_vm._v("Simpan Aplikasi")])]),(_vm.dataSaved)?_c('b-button',{staticClass:"mt-0 mt-md-2 ml-50",attrs:{"variant":"flat-success","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"14"}}),_vm._v(" Aplikasi Berhasil Disimpan ")],1):_vm._e()],1)],1)],1)],1),(_vm.errorMsg)?_c('div',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found ")]),_c('div',{staticClass:"alert-body"},[(typeof _vm.errorMsg === 'object')?_c('ul',_vm._l((_vm.errorMsg),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "),_vm._l((item),function(child,indexs){return _c('span',{key:indexs},[_vm._v(" "+_vm._s(child)+" ")])})],2)}),0):_c('span',[_vm._v(_vm._s(_vm.errorMsg))])])])],1):_vm._e(),_c('b-modal',{ref:"modal-komponen",attrs:{"id":"modal-komponen","hide-footer":"","centered":"","scrollable":"","size":"lg"}},[_c('b-card-text',[_c('vue-pdf-app',{staticStyle:{"height":"90vh"},attrs:{"pdf":_vm.fileDoc}})],1)],1),_c('b-modal',{ref:"modal-warning",attrs:{"id":"modal-warning","cancel-variant":"outline-secondary","ok-variant":"warning","cancel-title":"Batal","ok-title":"Ya, Hapus!","modal-class":"modal-warning","centered":"","title":"Attention!"},on:{"cancel":function($event){return _vm.clearDeleteItem()},"ok":function($event){return _vm.deleteItem()}}},[_c('b-card-text',[_vm._v(" Hapus Dokumen Tambahan? ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }