<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Infrastruktur
        </h5>
        <small class="text-muted">Arsitektur Domain Infrastruktur SPBE untuk seluruh Infrastruktur yang terkait dengan kegiatan.</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col :md="coreData.tahun_anggaran===2025? 6 : 12">
        <b-row>
          <alert-module-detail :doc-data="appData" />
          <b-col :md="coreData.tahun_anggaran===2025? 12 : 4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Domain Infrastruktur"
              label-for="vi-inf_domain_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Domain Infrastruktur"
                rules="required"
              >
                <v-select
                  id="vi-inf_domain_id"
                  v-model="inf_domain_id"
                  required
                  name="inf_domain_id"
                  :options="referenceData.ref_domain_infra"
                  placeholder="Domain Infrastruktur"
                  label="nama"
                  @input="getAreaInfra()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran===2025? 12 : 4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Area Infrastruktur"
              label-for="vi-inf_area_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Area Infrastruktur"
                rules="required"
              >
                <v-select
                  id="vi-inf_area_id"
                  v-model="inf_area_id"
                  required
                  name="inf_area_id"
                  :options="daftarAreaInfra"
                  placeholder="Area Infra"
                  label="nama"
                  @input="getKategoriInfra()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="coreData.tahun_anggaran===2025? 12 : 4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Kategori Infrastruktur"
              label-for="vi-inf_category_id"
            >
              <v-select
                id="vi-inf_category_id"
                v-model="inf_category_id"
                name="inf_category_id"
                :options="daftarKategoriInfra"
                :disabled="(daftarKategoriInfra.length === 0) ? true : false"
                placeholder="Kategori Infrastruktur"
                label="nama"
                @input="SetNamaKategoriInfra()"
              />
            </b-form-group>
          </b-col>
          <b-col
            :md="coreData.tahun_anggaran===2025? 12 : 6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="`Nama ${inf_domain_id.name} yang digunakan.`"
              :label="`Nama ${nama_infra}`"
              label-for="vi-nama"
            >
              <validation-provider
                #default="{ errors }"
                :name="`Nama ${nama_infra}`"
                rules="required"
              >
                <b-form-input
                  id="vi-nama"
                  v-model="appData.nama"
                  required
                  name="nama"
                  :placeholder="`Nama ${nama_infra}`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :md="coreData.tahun_anggaran===2025? 12 : 6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan status kegiatan/pengadaan Infra.'"
              label="Status Kegiatan"
              label-for="vi-inf_status_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Status kegiatan"
                rules="required"
              >
                <v-select
                  id="vi-inf_status_id"
                  v-model="inf_status_id"
                  required
                  name="inf_status_id"
                  :options="referenceData.ref_status_kegiatan"
                  placeholder="Status Kegiatan"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <div style="padding:10px;min-height:400px;border:1px solid #999">
          <h5>Data SIA SPBE</h5>
          <div
            v-for="(value, index) in siaMap"
            :key="index"
          >
            <template v-if="typeof value === 'object' && value !== null">
              <span class="font-weight-bolder">{{ index }}:</span>
              <Nested-sia-detail :obj="value" />
            </template>
            <template v-else>
              <span class="font-weight-bolder">{{ index }}:</span> {{ value }}
            </template>
          </div>
        </div>
      </b-col>

      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>

    <template v-if="inf_domain_id.domain_infra_id === 3 && inf_area_id.area_infra_id === 1">
      <!-- Type Sistem Integrasi : Jaringan Intra Pemerintah -->
      <infra-sis-jar
        ref="sisJar"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template v-else-if="inf_domain_id.domain_infra_id === 3 && inf_area_id.area_infra_id === 7">
      <!-- Type Sistem Integrasi : Sistem Penghubung Layanan Pemerintah -->
      <infra-sis-hub
        ref="sisJar"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 3">
      <!-- Type Platform : Komputasi Awan -->
      <infra-plat-cloud
        ref="platCloud"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 8"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Software -->
      <infra-plat-kia-soft
        ref="platKiaSoft"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 7"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Server -->
      <infra-plat-kia-server
        ref="platKiaServer"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 5"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Media Penyimpanan -->
      <infra-plat-kia-storage
        ref="platKiaStorage"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 3"
    >
      <infra-plat-kia-network
        ref="platKiaNetwork"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 4"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Keamanan -->
      <infra-plat-kia-security
        ref="platKiaSecurity"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 6"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Periferal -->
      <infra-plat-kia-periferal
        ref="platKiaPeriferal"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <!-- Type Fasilitas Komputasi : ALL -->
    <template v-else>
      <infra-fasil
        ref="fasil"
        :inf-data="appData"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>

      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(appData.total_anggaran) ? ': Rp. ' + Number(appData.total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="appData.total_anggaran"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="appData.kak_uri"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>{{ appData.kak_uri.substring(appData.kak_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25"
            @click="viewFile(appData.kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(appData.kak_uri)"
            class="btn btn-sm btn-info mr-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
        </p>
      </b-col>
      <b-col
        v-if="appData.additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <div
          v-for="(data, index) in appData.additional_docs"
          :id="`dt-${data.document_id}`"
          :key="index"
          class="d-flex align-items-center mb-50"
        >
          <div class="flex-fill">
            {{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}
          </div>
          <b-button
            variant="success"
            size="sm"
            class="mr-25"
            @click="viewFile(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <b-button
            variant="outline-danger"
            size="sm"
            class="mr-25"
            @click="removeFile(data.document_id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            /></b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf)'"
          label="Ubah Kerangka Acuan Kerja (dalam format PDF)"
          label-for="file_kak"
        >
          <b-form-file
            ref="file_kak"
            v-model="file1"
            name="file_kak"
            class="mt-1"
            :required="(!appData.kak_uri) ? true : false"
            @input="checkFile"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Tambahan (dalam format .pdf)'"
          label="Tambah Dokumen Tambahan (dalam format PDF)"
          label-for="file_extra_doc"
        >
          <b-form-file
            ref="file_extra_doc"
            v-model="file2"
            name="file_extra_doc"
            class="mt-1"
            multiple
            @input="checkFile"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Dokumen Tambahan?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BLink, BModal, BCardText, BButton, BFormFile, BFormInput, BRow, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import VuePdfApp from 'vue-pdf-app'

import AlertModuleDetail from '../detail/AlertModuleDetail.vue'
import InfraFasil from '../template-infra/InfraFasil.vue'
import InfraSisJar from '../template-infra/InfraSisJar.vue'
import InfraSisHub from '../template-infra/InfraSisHub.vue'
import InfraPlatCloud from '../template-infra/InfraPlatCloud.vue'
import InfraPlatKiaSoft from '../template-infra/InfraPlatKiaSoft.vue'
import InfraPlatKiaServer from '../template-infra/InfraPlatKiaServer.vue'
import InfraPlatKiaStorage from '../template-infra/InfraPlatKiaStorage.vue'
import InfraPlatKiaNetwork from '../template-infra/InfraPlatKiaNetwork.vue'
import InfraPlatKiaSecurity from '../template-infra/InfraPlatKiaSecurity.vue'
import InfraPlatKiaPeriferal from '../template-infra/InfraPlatKiaPeriferal.vue'
import NestedSiaDetail from '../detail/NestedSia.vue'

export default {
  components: {
    BCardText,
    BModal,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,

    AlertModuleDetail,
    InfraFasil,
    InfraSisJar,
    InfraSisHub,
    InfraPlatCloud,
    InfraPlatKiaSoft,
    InfraPlatKiaServer,
    InfraPlatKiaStorage,
    InfraPlatKiaNetwork,
    InfraPlatKiaSecurity,
    InfraPlatKiaPeriferal,
    VuePdfApp,
    NestedSiaDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    siaGuid: {
      type: String,
      default: '',
    },
    siaKegiatanGuid: {
      type: String,
      default: '',
    },
    siaMapData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        ref_domain_infra: [],
        ref_area_infra: [],
        ref_kategori_infra: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
        ref_status_kegiatan: [],
        ref_kepemilikan: [],
        ref_teknik_penyimpanan: [],
        ref_data_tematik: [],
        ref_jenis_lisensi: [],
        ref_jenis_prosesor: [],
      },
      nama_infra: 'Pusat Data',
      appData: {
        additional_docs: [],
      },
      data_utama_id: '',
      errorMsg: '',
      infrastruktur_id: '',
      dataSaved: false,
      reqData: 0,
      fileDoc: '',
      inf_domain_id: {
        domain_infra_id: 1,
        nama: 'Fasilitas Komputasi',
      },
      inf_area_id: {
        area_infra_id: 4,
        nama: 'Pusat Data',
      },
      inf_category_id: null,
      nama: '',
      inf_status_id: '',
      total_anggaran: '',
      sumber_dana: '',
      jenis_belanja: '',
      jenis_pengadaan: '',
      file1: null,
      file2: [],
      daftarAreaInfra: [],
      daftarKategoriInfra: [],
      childData: {},
      toDelData: '',
      siaLoad: false,
      siaFailed: false,
      siaFailedMsg: '',
      siaFailedLink: false,
      siaItems: {},
      siaMap: {},
    }
  },
  watch: {
    actionStep() {
      this.simpanInfra()
    },
    siaGuid() {
      // this.getSiaData()
    },
    siaKegiatanGuid() {
      // this.getSiaData()
    },
    siaMapData() {
      this.inputSia()
    },
  },
  mounted() {
    this.data_utama_id = Number(this.$route.params.id)
    this.getReference()
  },
  methods: {
    inputSia() {
      if (Object.keys(this.siaMapData).length > 0) {
        this.dataLoad = false
        this.siaLoad = true
        document.getElementById('loading-bg').style.display = 'block'

        // find domain
        this.inf_domain_id = ''
        this.referenceData.ref_domain_infra.forEach(entry => {
          if (this.siaMapData.ega.domain_infra === entry.domain_infra_id) {
            this.inf_domain_id = entry
            this.getAreaInfra()
          }
        })
        this.inf_area_id = ''
        this.referenceData.ref_area_infra.forEach(entry => {
          if (this.siaMapData.ega.area_infra === entry.area_infra_id) {
            this.inf_area_id = entry
            this.getKategoriInfra()
          }
        })

        if (this.siaMapData.ega.kategori_infra) {
          this.inf_category_id = ''
          this.referenceData.ref_kategori_infra.forEach(entry => {
            if (this.siaMapData.ega.kategori_infra === entry.kategori_infra_id) {
              this.inf_category_id = entry
            }
          })
        }

        if (this.siaMapData.name) {
          this.appData.nama = this.siaMapData.name
        }
        if (this.siaMapData.lokasi) {
          this.appData.lokasi = this.siaMapData.lokasi
        }
        if (this.siaMapData.bandwidth_internet) {
          this.appData.bandwidth = this.siaMapData.bandwidth_internet
        }

        if (this.siaMapData.kepemilikan) {
          this.referenceData.ref_kepemilikan.forEach(entry => {
            if (this.siaMapData.kepemilikan === entry.nama) {
              this.appData.kepemilikan = entry
            }
          })
        }

        if (this.siaMapData.teknik_penyimpanan) {
          this.referenceData.ref_teknik_penyimpanan.forEach(entry => {
            if (this.siaMapData.teknik_penyimpanan === entry.nama) {
              this.appData.storage_technic = entry
            }
          })
        }

        if (this.siaMapData.jenis_processor) {
          this.referenceData.ref_jenis_prosesor.forEach(entry => {
            if (this.siaMapData.jenis_processor === entry.nama) {
              this.appData.processor_type = entry
            }
          })
        }

        if (this.siaMapData.jenis_lisensi) {
          this.referenceData.ref_jenis_lisensi.forEach(entry => {
            if (this.siaMapData.jenis_lisensi === entry.nama) {
              this.appData.license_type = entry
            }
          })
        }

        if (this.siaMapData.unit_kerja) {
          this.appData.unit_kerja = this.siaMapData.unit_kerja
        }
        if (this.siaMapData.klasifikasi_tier) {
          this.appData.tier = this.siaMapData.klasifikasi_tier
        }
        if (this.siaMapData.sistem_pengamanan) {
          this.appData.sistem_pengaman = this.siaMapData.sistem_pengamanan
        }
        if (this.siaMapData.nama_pemilik) {
          this.appData.nama_pemilik = this.siaMapData.nama_pemilik
        }
        if (this.siaMapData.tipe_media_jaringan) {
          this.appData.tipe_media_jaringan = this.siaMapData.tipe_media_jaringan
        }
        if (this.siaMapData.media_lainnya) {
          this.appData.media_lainnya = this.siaMapData.media_lainnya
        }
        if (this.siaMapData.uraian_deskripsi) {
          this.appData.deskripsi = this.siaMapData.uraian_deskripsi
        }
        if (this.siaMapData.jenis_sistem_operasi) {
          this.appData.perangkat_lunak = this.siaMapData.jenis_sistem_operasi
        }
        if (this.siaMapData.nama_pemilik) {
          this.appData.pemilik_lisensi = this.siaMapData.nama_pemilik
        }
        if (this.siaMapData.validitas_lisensi_perangkat_lunak) {
          this.appData.validasi_lisensi = this.siaMapData.validitas_lisensi_perangkat_lunak
        }
        if (this.siaMapData.jangka_waktu_layanan) {
          this.appData.jangka_waktu_layanan = this.siaMapData.jangka_waktu_layanan
        }
        if (this.siaMapData.unit_operational) {
          this.appData.unit_operasional = this.siaMapData.unit_operational[0].name
        }
        if (this.siaMapData.kapasitas_memori) {
          this.appData.kapasitas_memori = this.siaMapData.kapasitas_memori
        }
        if (this.siaMapData.kapasitas_penyimpanan) {
          this.appData.kapasitas_penyimpanan = this.siaMapData.kapasitas_penyimpanan
        }

        if (this.siaMapData.metode_akses_data_sharing) {
          this.appData.metode_akses_penyimpanan = this.siaMapData.metode_akses_data_sharing
        }

        // Jenis jaringan
        if (this.siaMapData.jenis_jaringan) {
          this.appData.jenis = this.siaMapData.jenis_jaringan
        }
        if (this.siaMapData.bandwidth) {
          this.appData.bandwidth = this.siaMapData.bandwidth
        }
        // Jenis Sistem Penghubung
        if (this.siaMapData.jenis_sistem_penghubung) {
          this.appData.jenis = this.siaMapData.jenis_sistem_penghubung
        }
        // Jenis Platform Cloud
        if (this.siaMapData.jenis_komputasi_awan_pemerintahan) {
          this.appData.jenis = this.siaMapData.jenis_komputasi_awan_pemerintahan
        }
        // Jenis Perangkat Lunak
        if (this.siaMapData.jenis_perangkat_lunak) {
          this.appData.jenis = this.siaMapData.jenis_perangkat_lunak
        }
        // Jenis Penggunaan Server
        if (this.siaMapData.jenis_penggunaan_server) {
          this.appData.jenis = this.siaMapData.jenis_penggunaan_server
        }
        if (this.siaMapData.lokasi_server) {
          this.appData.lokasi = this.siaMapData.lokasi_server[0].name
        }
        // Jenis Periferal
        if (this.siaMapData.jenis_periferal) {
          this.appData.jenis = this.siaMapData.jenis_periferal
        }
        // Jenis Perangkat Keamanan
        if (this.siaMapData.jenis_perangkat_keamanan) {
          this.appData.jenis = this.siaMapData.jenis_perangkat_keamanan
        }
        // Jenis Perangkat Jaringan
        if (this.siaMapData.jenis_perangkat_jaringan) {
          this.appData.jenis = this.siaMapData.jenis_perangkat_jaringan
        }

        this.siaMap = this.siaMapData
        delete this.siaMap.ega
        delete this.siaMap.guid

        this.siaLoad = false
        this.dataLoad = true
        document.getElementById('loading-bg').style.display = 'none'
      }
    },
    checkFile(input) {
      if (input.size > 100 * 1024 * 1024) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Ukuran Dokumen Terlalu Besar`,
        }
      } else if (input.type !== 'application/pdf') {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Jenis Dokumen Tidak Sesuai`,
        }
      } else {
        this.alert = false
      }
    },
    getReference() {
      this.$http.get('/clearance/infrastruktur/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            this.setReference()
          }
        })
    },
    setReference() {
      this.$http.get('/clearance/infrastruktur', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            [this.appData] = res.data.data
            this.referenceData.ref_domain_infra.map(contact => {
              if (contact.domain_infra_id === this.appData.domain_infra_id) {
                this.inf_domain_id = contact
              }
              return true
            })
            this.getAreaInfra()
            this.referenceData.ref_area_infra.map(contact => {
              if (contact.area_infra_id === this.appData.area_infra_id) {
                this.inf_area_id = contact
                this.nama_infra = this.inf_area_id.nama
              }
              return true
            })
            this.getKategoriInfra()
            this.referenceData.ref_kategori_infra.map(contact => {
              if (contact.kategori_infra_id === this.appData.kategori_infra_id) {
                this.inf_category_id = contact
                this.nama_infra = this.inf_category_id.nama
              }
              return true
            })
            this.referenceData.ref_status_kegiatan.map(contact => {
              if (contact.nama === this.appData.status_kegiatan) {
                this.inf_status_id = contact
              }
              return true
            })
            this.referenceData.ref_kepemilikan.map(contact => {
              if (contact.nama === this.appData.kepemilikan) {
                this.appData.kepemilikan = contact
              }
              return true
            })
            this.referenceData.ref_jenis_lisensi.map(contact => {
              if (contact.nama === this.appData.lisensi_infrastruktur) {
                this.appData.license_type = contact
              }
              return true
            })
            this.referenceData.ref_data_tematik.map(contact => {
              if (contact.nama === this.appData.data_tematik) {
                this.appData.data_tematik_id = contact
              }
              return true
            })
            this.referenceData.ref_jenis_prosesor.map(contact => {
              if (contact.nama === this.appData.jenis_prosesor) {
                this.appData.processor_type = contact
              }
              return true
            })
            this.referenceData.ref_teknik_penyimpanan.map(contact => {
              if (contact.nama === this.appData.teknis_penyimpanan) {
                this.appData.storage_technic = contact
              }
              return true
            })
          }
        })
    },
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    removeFile(value) {
      this.toDelData = value
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
    },
    deleteItem() {
      this.$http.post('/clearance/infrastruktur/file/delete', {
        document_id: this.toDelData,
        infrastruktur_id: this.appData.infrastruktur_id,
      }, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById(`dt-${this.toDelData}`).remove()
            this.clearDeleteItem()
          }
        })
    },
    getAreaInfra() {
      this.daftarAreaInfra.splice(0)
      this.inf_area_id = ''
      this.inf_category_id = null

      const filterVal = this.inf_domain_id.domain_infra_id
      const refData = this.referenceData.ref_area_infra
      refData.map(contact => {
        if (contact.domain_infra_id === filterVal) {
          this.daftarAreaInfra.push(contact)
        }
        return true
      })
    },
    getKategoriInfra() {
      this.daftarKategoriInfra.splice(0)
      this.inf_category_id = ''

      const filterVal = this.inf_area_id.area_infra_id
      this.nama_infra = this.inf_area_id.nama

      const refData = this.referenceData.ref_kategori_infra
      refData.map(contact => {
        if (contact.area_infra_id === filterVal) {
          this.daftarKategoriInfra.push(contact)
        }
        return true
      })
    },
    SetNamaKategoriInfra() {
      this.nama_infra = this.inf_category_id.nama
    },
    simpanInfra() {
      if (!this.appData.kak_uri && !this.file1) {
        const data = {
          status: false,
          msg: 'File KAK Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
      } else {
        this.saveInfra()
      }
    },
    saveInfra() {
      this.errorMsg = ''
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        infrastruktur_id: this.appData.infrastruktur_id,
        instansi_id: this.appData.instansi_id,
        data_utama_id: this.data_utama_id,
        domain_infra_id: this.inf_domain_id.domain_infra_id,
        area_infra_id: this.inf_area_id.area_infra_id,
        nama: this.appData.nama,
        status_kegiatan_id: this.inf_status_id.status_kegiatan_id,
        total_anggaran: Number(this.appData.total_anggaran),
        bandwidth: this.appData.bandwidth,
        lokasi: this.appData.lokasi,
        unit_kerja: this.appData.unit_kerja,
        tier: this.appData.tier,
        sistem_pengaman: this.appData.sistem_pengaman,
        jenis: this.appData.jenis,
        nama_pemilik: this.appData.nama_pemilik,
        tipe_media_jaringan: this.appData.tipe_media_jaringan,
        media_lainnya: this.appData.media_lainnya,
        deskripsi: this.appData.deskripsi,
        nama_jaringan: this.appData.nama_jaringan,
        aplikasi_terhubung: this.appData.aplikasi_terhubung,
        biaya_layanan: this.appData.biaya_layanan,
        unit_pengembang: this.appData.unit_pengembang,
        unit_operasional: this.appData.unit_operasional,
        jangka_waktu_layanan: this.appData.jangka_waktu_layanan,
        perangkat_lunak: this.appData.perangkat_lunak,
        kapasitas_memori: this.appData.kapasitas_memori,
        kapasitas_penyimpanan: this.appData.kapasitas_penyimpanan,
        pemilik_lisensi: this.appData.pemilik_lisensi,
        validasi_lisensi: this.appData.validasi_lisensi,
        metode_akses_penyimpanan: this.appData.metode_akses_penyimpanan,
      }
      if (this.inf_category_id !== null) {
        metaUtama.kategori_infra_id = this.inf_category_id.kategori_infra_id
      }
      if (this.appData.kepemilikan) {
        metaUtama.kepemilikan_id = this.appData.kepemilikan.kepemilikan_id
      }
      if (this.appData.data_tematik_id) {
        metaUtama.data_tematik_id = this.appData.data_tematik_id.data_tematik_id
      }
      if (this.appData.processor_type) {
        metaUtama.jenis_prosesor_id = this.appData.processor_type.jenis_prosesor_id
      }
      if (this.appData.storage_technic) {
        metaUtama.teknis_penyimpanan_id = this.appData.storage_technic.teknis_penyimpanan_id
      }
      if (this.appData.license_type) {
        metaUtama.jenis_lisensi_id = this.appData.license_type.jenis_lisensi_id
      }

      this.$http.post('/clearance/infrastruktur', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const status = true
            this.infrastruktur_id = res.data.data.infrastruktur_id

            const promise1 = new Promise(resolve => {
              if (this.file1) {
                const formFile = new FormData()
                formFile.append('clearance_id', this.coreData.clearance_id)
                formFile.append('data_utama_id', this.data_utama_id)
                formFile.append('infrastruktur_id', this.infrastruktur_id)
                formFile.append('kak_file', this.file1)

                this.$http.post('/clearance/infrastruktur/file/upload', formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(uplds => {
                    if (uplds.data.status === 'success') {
                      this.appData.kak_uri = uplds.data.kak_uri
                      resolve(true)
                    } else {
                      const data = {
                        status: false,
                        msg: uplds.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = uplds.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    const data = {
                      status: false,
                      msg: error.response.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.errorMsg = error.response.data.error
                    this.$emit('step-completed', data)
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            const promise2 = new Promise(resolve => {
              if (this.file2.length !== 0) {
                for (let i = 0; i < this.file2.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('clearance_id', this.coreData.clearance_id)
                  formFile.append('data_utama_id', this.data_utama_id)
                  formFile.append('infrastruktur_id', this.infrastruktur_id)
                  formFile.append('additional_files', this.file2[i])

                  this.$http.post('/clearance/infrastruktur/file/upload', formFile, {
                    params: {
                      token: localStorage.getItem('userToken'),
                    },
                  })
                    .then(uplds => {
                      if (uplds.data.status === 'success') {
                        this.appData.additional_docs.push(uplds.data.data[0])
                        resolve(true)
                      } else {
                        const data = {
                          status: false,
                          msg: uplds.data.error,
                        }
                        document.getElementById('loading-bg').style.display = 'none'
                        this.errorMsg = uplds.data.error
                        this.$emit('step-completed', data)
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      const data = {
                        status: false,
                        msg: error.response.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = error.response.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise1, promise2, status]).then(values => {
              if (values[0] && values[1] && values[2]) {
                const data = {
                  status: true,
                  value: values,
                  id: this.appData.id,
                  type: 'inf',
                  msg: {
                    data_utama_id: this.data_utama_id,
                    aplikasi_id: this.aplikasi_id,
                  },
                }
                document.getElementById('loading-bg').style.display = 'none'
                this.$refs.file_kak.reset()
                this.$refs.file_extra_doc.reset()
                this.file1 = null
                this.file2 = []
                this.dataSaved = true
                this.errorMsg = ''
                this.$emit('step-completed', data)
              }
            })
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.errorMsg = `${res.data.message}: ${res.data.error}`
          }
        })
        .catch(error => {
          const data = {
            status: false,
            id: this.appData.id,
            type: 'inf',
            msg: error.response.data.error,
          }
          document.getElementById('loading-bg').style.display = 'none'
          this.errorMsg = error.response.data.error
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
