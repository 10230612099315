<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Keamanan
        </h5>
        <small class="text-muted">Arsitektur Domain Keamanan SPBE.</small>
      </b-col>
      <alert-module-detail :doc-data="dataSec" />
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Jenis standar keamanan yang diacu dan menjadi prioritas oleh setiap IPPD.'"
          label="Jenis Standar Keamanan"
          label-for="vi-standar_keamanan_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Jenis Standar Keamanan"
            rules="required"
          >
            <v-select
              id="vi-standar_keamanan_id"
              v-model="standar_keamanan_id"
              required
              name="standar_keamanan_id"
              :options="referenceData.ref_standar_keamanan"
              placeholder="Jenis Standar Keamanan"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Nama dari jenis standar keamanan yang diacu dan menjadi prioritas oleh setiap IPPD.'"
          label="Keterangan Nama Standar"
          label-for="vi-nama_standar"
        >
          <b-form-input
            id="vi-nama_standar"
            v-model="dataSec.nama_standar"
            name="nama_standar"
            placeholder="Keterangan Nama Standar"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Hasil dari pelaksanaan Audit Keamanan SPBE untuk Aplikasi dan Infrastruktur SPBE.'"
          label="Hasil Audit Keamanan SPBE"
          label-for="vi-hasil_audit_id"
        >
          <v-select
            id="vi-hasil_audit_id"
            v-model="hasil_audit_id"
            name="hasil_audit_id"
            :options="referenceData.ref_hasil_audit"
            placeholder="Hasil Audit Keamanan SPBE"
            label="nama"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Tanggal penyerahan laporan Audit Keamanan SPBE terbaru untuk Aplikasi dan Infrastruktur SPBE yang bersesuaian.'"
          label="Tanggal Pelaksanaan Audit"
          label-for="vi-tanggal_audit"
        >
          <b-form-datepicker
            id="vi-tanggal_audit"
            v-model="dataSec.tanggal_audit"
            name="tanggal_audit"
            placeholder="Tanggal Pelaksanaan Audit"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Program kerja atau kegiatan Keamanan SPBE yang dilaksanakan oleh setiap IPPD sebagai upaya dalam meminimalkan dampak risiko Keamanan SPBE.'"
          label="Penerapan Keamanan"
          label-for="vi-penerapan_keamanan_id"
        >
          <v-select
            id="vi-penerapan_keamanan_id"
            v-model="penerapan_keamanan_id"
            name="penerapan_keamanan_id"
            :options="referenceData.ref_penerapan_keamanan"
            placeholder="Penerapan Keamanan"
            label="nama"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Pengujian kelaikan keamanan yang telah dilaksanakan terhadap pengendalian data dan informasi, persyaratan keamanan Aplikasi Umum SPBE, dan persyaratan keamanan Infrastruktur SPBE Nasional.'"
          label="Pengujian kelaikan keamanan"
          label-for="vi-kelaikan_keamanan_id"
        >
          <v-select
            id="vi-kelaikan_keamanan_id"
            v-model="kelaikan_keamanan_id"
            name="kelaikan_keamanan_id"
            :options="referenceData.ref_kelaikan_keamanan"
            placeholder="Pengujian kelaikan keamanan"
            label="nama"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormDatepicker, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'

import AlertModuleDetail from '../detail/AlertModuleDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
    AlertModuleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      data_utama_id: '',
      referenceData: {
        ref_hasil_audit: [],
        ref_kelaikan_keamanan: [],
        ref_penerapan_keamanan: [],
        ref_standar_keamanan: [],
      },
      dataSec: { is_verified: '' },
      standar_keamanan_id: '',
      nama_standar: '',
      hasil_audit_id: '',
      tanggal_audit: '',
      penerapan_keamanan_id: null,
      kelaikan_keamanan_id: null,
      clearanceStatus: null,
    }
  },
  watch: {
    actionStep() {
      this.saveKeamanan()
    },
  },
  created() {
    this.data_utama_id = this.$route.params.id
  },
  mounted() {
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/security-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataSec = res.data.data
            this.referenceData = this.dataSec.references

            if (this.dataSec.standar_keamanan_id !== '') {
              this.standar_keamanan_id = {
                standar_keamanan_id: this.dataSec.standar_keamanan_id,
                nama: this.dataSec.standar_keamanan,
              }
            }
            if (this.dataSec.hasil_audit_id !== '') {
              this.hasil_audit_id = {
                hasil_audit_id: this.dataSec.hasil_audit_id,
                nama: this.dataSec.hasil_audit,
              }
            }
            if (this.dataSec.penerapan_keamanan_id !== '') {
              this.penerapan_keamanan_id = {
                penerapan_keamanan_id: this.dataSec.penerapan_keamanan_id,
                nama: this.dataSec.penerapan_keamanan,
              }
            }
            if (this.dataSec.kelaikan_keamanan_id !== '') {
              this.kelaikan_keamanan_id = {
                kelaikan_keamanan_id: this.dataSec.kelaikan_keamanan_id,
                nama: this.dataSec.kelaikan_keamanan,
              }
            }
          } else {
            this.getSoloReference()
          }
        })
    },
    getSoloReference() {
      this.$http.get('/clearance/security-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
    saveKeamanan() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.data_utama_id,
        instansi_id: this.dataSec.instansi_id,
        standar_keamanan_id: (this.standar_keamanan_id) ? this.standar_keamanan_id.standar_keamanan_id : null,
      }
      if (this.dataSec.nama_standar !== '') {
        metaUtama.nama_standar = this.dataSec.nama_standar
      }
      if (this.dataSec.tanggal_audit !== '') {
        metaUtama.tanggal_audit = this.dataSec.tanggal_audit
      }
      if (this.dataSec.keamanan_id !== '') {
        metaUtama.keamanan_id = this.dataSec.keamanan_id
      }
      if (this.hasil_audit_id) {
        metaUtama.hasil_audit_id = this.hasil_audit_id.hasil_audit_id
      }
      if (this.penerapan_keamanan_id) {
        metaUtama.penerapan_keamanan_id = this.penerapan_keamanan_id.penerapan_keamanan_id
      }
      if (this.kelaikan_keamanan_id) {
        metaUtama.kelaikan_keamanan_id = this.kelaikan_keamanan_id.kelaikan_keamanan_id
      }
      this.$http.post('/clearance/security-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
